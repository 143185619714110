import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@base-component';
import {
  IFilterVariable,
  IFilterVariableStateMap,
  IThresholdV2,
  IThresholdsV2,
  IWidgetClickTargetV2,
  WidgetType,
  variableValue,
} from '@interfaces';
import { NbDialogConfig, NbDialogService } from '@nebular/theme';
import {
  Actions,
  ofActionCompleted,
  ofActionSuccessful,
  Store,
} from '@ngxs/store';
import { UserActionDialogComponent } from '@root/core-components/user-action-dialog/user-action-dialog.component';
import { App, Widget, WidgetEditor } from '@root/state/app.actions';
import { IAppStateModel } from '@root/state/app.model';
import {
  widgetHighlightStateToWidgetCSSClassMap,
  WidgetStateObject,
} from '@root/state/state-model-objects/widget.state-model';
import { EventQueueService, StateService } from '@services';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  lastValueFrom,
  merge,
  of,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  skip,
  startWith,
  take,
  takeUntil,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';
import { CopyWidgetToDashboardDialogComponent } from './copy-widget-to-dashboard-dialog/copy-widget-to-dashboard-dialog.component';
import { DrilldownTableComponent } from './drilldown-table/drilldown-table.component';
import { CopyToTemplateDialogComponent } from '../copy-to-template-dialog/copy-to-template-dialog.component';
import { buildModalData } from './widget-helpers';
import { HttpRequestService } from '@root/services/http-request.service';
import { GridsterComponentInterface, GridsterItem } from 'angular-gridster2';
import { ActivatedRoute, Router } from '@angular/router';
import { buildFilterVariableDisplayValues } from '@helpers';

interface IThresholdSnooze {
  isSnoozed: boolean;
  snoozeSeconds: number;
  snoozeUntil: Date | null;
  snoozeTimeout: ReturnType<typeof setTimeout> | null;
}

interface IWidgetAudioState {
  audioMuted: boolean;
  snooze: IThresholdSnooze;
  intervalSet: boolean;
  interval: number;
  nextPlayTime: Date | null;
  thresholdWasHit: boolean;
}

@Component({
  selector: 'resplendent-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() id: string;
  @Input() dashId: string;
  @Input() isInGridster = true;
  @Input() isInEditor = false;
  @Input() isNewWidget = false;

  @ViewChild('gridsterItem') gridsterItem: GridsterComponentInterface;

  nonRepeatingSoundsState = {};

  widget$ = this.store
    .select((state) => (state.app as IAppStateModel).appState.widgets[this.id])
    .pipe(
      filter((widget) => !!widget),
      tap((widget) => {
        // When the widget updates, we can check if the filter
        // variable state has changed and update the indicator
        const variableIds = widget.getFilterVariableIds();
        if (variableIds.length) {
          this.widgetHasFilterVariablesSource$.next(true);
          const filterVariablesForThisWidget =
            this.filterVariablesForThisWidget(variableIds);
          this.filterVariablesForThisWidgetSource$.next(
            filterVariablesForThisWidget,
          );
        } else {
          this.widgetHasFilterVariablesSource$.next(false);
          this.filterVariablesForThisWidgetSource$.next([]);
        }
      }),
    );
  currentFilterVariableState$ = this.store.select(
    (state) =>
      (state.app as IAppStateModel).appState.filterVariableState
        ?.currentFilterVariableState,
  );

  widgetHasPreviewData$ = this.widget$.pipe(
    map((widget) => {
      let hasPreviewData = false;
      if (!widget) return hasPreviewData;

      widget?.calcArray.forEach((calc) => {
        if (calc.getActiveData()?.extras?.is_preview) hasPreviewData = true;
      });
      return hasPreviewData;
    }),
  );
  isShared$ = this.store.select((s) => {
    const state = (s.app as IAppStateModel).appState;
    const widget = state.widgets[this.id];
    const dashId = widget.dashboardV2Id;
    // We can't assume that widget is on a dash, if it's not it can't be shared
    const currentDash = state.currentDashboard;
    if (!dashId || !currentDash || dashId !== currentDash.id) return false;
    return currentDash.isShared;
  });
  showModalData$ = this.store.select((s) => {
    const state = (s.app as IAppStateModel).appState;
    const widget = state.widgets[this.id];
    const dashId = widget.dashboardV2Id;
    // We can't assume that widget is on a dash, if it's not it can't be shared
    const currentDash = state.currentDashboard;
    if (!dashId || !currentDash || dashId !== currentDash.id) return true;
    if (!currentDash.isShared) return true;
    return currentDash.showModalData;
  });
  currentDashboard$ = this.store.select(
    (select) => (select.app as IAppStateModel).appState.currentDashboard,
  );
  dashboardDisplayState$ = this.currentDashboard$.pipe(
    map((dashboard) => dashboard?.displayState),
  );
  dashboardVersion$ = this.currentDashboard$.pipe(
    map((dashboard) => dashboard?.version),
  );
  widgetTitleFontSize$ = this.currentDashboard$.pipe(
    map((dashboard) => dashboard?.widgetTitleFontSize),
  );
  activeFilterView$ = this.store.select((select) => {
    const state = (select.app as IAppStateModel).appState;
    const currentDashboard = state.currentDashboard;
    if (!currentDashboard) return null;
    const activeFilterViewId = currentDashboard.activeFilterViewId;
    if (!activeFilterViewId) return null;
    const filterViews = state.filterViews;
    return filterViews[activeFilterViewId];
  });

  widgetHighlightStateCssClassMap = widgetHighlightStateToWidgetCSSClassMap;

  private handleClickEvents$: BehaviorSubject<IWidgetClickTargetV2> =
    new BehaviorSubject(null);
  private thresholdAudio: null | HTMLAudioElement = null;
  private thresholdAudioTimeout: null | ReturnType<typeof setTimeout> = null;
  private widgetAudioStateSource$: BehaviorSubject<IWidgetAudioState> =
    new BehaviorSubject({
      audioMuted: false,
      snooze: {
        isSnoozed: false,
        snoozeSeconds: 0,
        snoozeUntil: null,
        snoozeTimeout: null,
      },
      intervalSet: false,
      interval: 0,
      nextPlayTime: null,
      thresholdWasHit: false,
    });
  public widgetAudioState$ = this.widgetAudioStateSource$.asObservable();

  // Used to determine if we should show the widget indicators in the actions menu
  private indicatorsInActionsMenuSource$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public indicatorsInActionsMenu$ =
    this.indicatorsInActionsMenuSource$.asObservable();
  private componentWidthSource$: BehaviorSubject<number> = new BehaviorSubject(
    0,
  );
  public componentWidth$ = this.componentWidthSource$.asObservable();

  private widgetHasFilterVariablesSource$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public widgetHasFilterVariables$ =
    this.widgetHasFilterVariablesSource$.asObservable();
  private filterVariablesForThisWidgetSource$: BehaviorSubject<
    IFilterVariable[]
  > = new BehaviorSubject([]);
  public filterVariablesForThisWidget$ =
    this.filterVariablesForThisWidgetSource$.asObservable();

  // This is a combination of the filter variables for this widget and the current filter variable state.
  // It will be used to show which values in the filter variables are active for this widget
  filterVariableStateForThisWidget$: Observable<IFilterVariableStateMap> =
    combineLatest([
      this.filterVariablesForThisWidget$.pipe(startWith([])),
      this.currentFilterVariableState$.pipe(startWith(null)),
    ]).pipe(
      map(([filterVariablesForThisWidget, currentFilterVariableState]) => {
        if (!filterVariablesForThisWidget || !currentFilterVariableState)
          return null;
        const filterVariableStateForThisWidget: IFilterVariableStateMap = {};

        filterVariablesForThisWidget.forEach((filterVariable) => {
          const variableId = filterVariable.id;
          const variableState =
            currentFilterVariableState.state?.filterVariableState[variableId];
          if (!variableState) return;
          filterVariableStateForThisWidget[variableId] = variableState;
        });
        return filterVariableStateForThisWidget;
      }),
    );
  public buildFilterVariableDisplayStrings = buildFilterVariableDisplayValues;

  constructor(
    private store: Store,
    private actions: Actions,
    public stateService: StateService,
    private eventQueue: EventQueueService,
    private dialog: NbDialogService,
    private httpRequest: HttpRequestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    super();
  }

  get isChartJs() {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.id],
    );
    const chartJsTypes: WidgetType[] = [
      'bar',
      'line',
      'pie',
      'doughnut',
      'gauge',
      'number',
      'staticString',
      'dynamicString',
      'image',
      'iFrame',
      'funnel',
    ];
    return chartJsTypes.includes(widget.widgetType);
  }
  get isTable() {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.id],
    );
    return widget.widgetType === 'table';
  }
  get isMatrix() {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.id],
    );
    return widget.widgetType === 'matrix';
  }
  get hasData() {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.id],
    );
    return widget.hasSomeData;
  }

  ngOnInit() {
    if (!this.id) throw new Error('Widget must have an id');
    if (this.activatedRoute.snapshot.queryParams.widgetId === this.id) {
      this.editWidget();
    }
    // New widgets don't exist in the backend and editor is responsible for fetching the widget in it
    this.fetchWidgetObject();
    this.registerInitSubs();
  }

  ngOnDestroy(): void {
    if (this.thresholdAudioTimeout) {
      clearTimeout(this.thresholdAudioTimeout);
    }
  }

  private async fetchWidgetObject() {
    const currentWidgets = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets,
    );
    const fetchWidget =
      !this.isNewWidget && !this.isInEditor && !currentWidgets[this.id];
    if (fetchWidget) this.store.dispatch(new Widget.FetchWidget(this.id));
  }

  private async registerInitSubs() {
    this.registerHandleClickEvents();
    this.registerDataUpdates();
    this.registerWidgetDataUpdateSuccess();
    this.registerComponentWidth();
    this.registerDisplayStateChanges();
  }

  private async registerDisplayStateChanges() {
    this.dashboardDisplayState$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe((displayState) => {
        if (displayState === 'dashConfig') {
          if (this.gridsterItem?.el) {
            this.componentWidthSource$.next(this.gridsterItem.el.clientWidth);
          } else {
            this.componentWidthSource$.next(175);
          }
        }
      });
  }

  private async registerComponentWidth() {
    this.componentWidth$
      .pipe(takeUntil(this.isDestroyed$), debounceTime(250))
      .subscribe((width) => {
        this.indicatorsInActionsMenuSource$.next(width < 175);
      });
  }

  public async registerDataUpdates() {
    this.eventQueue
      .on('CALCULATION_DATA_UPDATE')
      .pipe(
        takeUntil(this.isDestroyed$),
        withLatestFrom(this.widget$),
        filter(
          ([calcDataUpdate, widget]) =>
            widget.isDataBasedWidget &&
            !this.isInEditor &&
            widget.caresAboutDataId(calcDataUpdate.calculation_uuid),
        ),
        tap(([calcDataUpdate, widget]) => {
          // If we get a data update and we don't have column info, fetch the widget
          // if (!widget.hasColumnInfo) {
          //   this.store.dispatch(new Widget.FetchWidget(widget.id));
          //   return;
          // }
          this.store.dispatch(
            new Widget.CalcDataUpdate(widget.id, calcDataUpdate),
          );
        }),
      )
      .subscribe();
  }

  public async registerHandleClickEvents() {
    this.handleClickEvents$
      .pipe(
        takeUntil(this.isDestroyed$),
        throttleTime(1000, undefined, { leading: true }),
        withLatestFrom(this.dashboardDisplayState$),
        withLatestFrom(this.showModalData$),
        withLatestFrom(this.widget$),
        skip(1),
        tap(([[[clickTarget, displayState], canShowModalData], widget]) => {
          if (widget.highlightState === 'disabled') return;
          if (!canShowModalData) {
            this.eventQueue.dispatch('SHOW_TOAST', {
              message: 'Drilldown is disabled for this dashboard',
              status: 'info',
              title: 'Info',
              duration: 3000,
            });
            return;
          }

          // If the dashboard is in select mode, toggle the widget's selected state
          const selectingWidgetsMode = this.store.selectSnapshot(
            (state) =>
              (state.app as IAppStateModel).appState.currentDashboard
                ?.selectingFilterViewItemWidgets,
          );
          if (selectingWidgetsMode) {
            if (widget.isLoading) {
              this.eventQueue.dispatch('SHOW_TOAST', {
                message: 'Cannot select a widget that is loading',
                status: 'info',
                title: 'Info',
                duration: 3000,
              });
              return;
            }
            this.toggleWidgetSelectedState();
          } else if (
            clickTarget &&
            (displayState !== 'dashConfig' || this.isInEditor)
          ) {
            this.showTable(clickTarget);
          } else if (clickTarget && displayState === 'dashConfig') {
            this.eventQueue.dispatch('SHOW_TOAST', {
              message: 'Save the dashboard to drill down',
              status: 'info',
              title: 'In Dashboard Configuration Mode',
              duration: 3000,
            });
          }
        }),
      )
      .subscribe();
  }

  private async registerWidgetDataUpdateSuccess() {
    this.widget$
      .pipe(
        takeUntil(this.isDestroyed$),
        debounceTime(1000),
        withLatestFrom(this.widgetAudioState$),
        tap(([widget, audioState]) => {
          if (widget.allCalcsHaveData) {
            this.checkAudioState(widget, audioState);
          }
        }),
      )
      .subscribe();
    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        ofActionCompleted(Widget.CalcDataUpdate),
        filter(
          (completedAction) => completedAction.action.widgetId === this.id,
        ),
        withLatestFrom(this.widget$),
        filter(
          ([completedAction, widget]) =>
            widget.id === completedAction.action.widgetId,
        ),
        withLatestFrom(this.widgetAudioState$),
        tap(([[, widget], audioState]) => {
          this.checkAudioState(widget, audioState);
        }),
      )
      .subscribe();
  }
  private checkAudioState(
    widget?: WidgetStateObject,
    audioState?: IWidgetAudioState,
  ) {
    if (!widget) {
      widget = this.store.selectSnapshot(
        (state) => (state.app as IAppStateModel).appState.widgets[this.id],
      );
    }
    if (!audioState) {
      audioState = this.widgetAudioStateSource$.value;
    }
    const widgetConfig = widget.config;
    if (
      widgetConfig.colorType.toggled &&
      widgetConfig.colorType.value === 'thresholds'
    ) {
      // A dictionary of calcUuids to the current and previous values
      const calcValueMap = widget.getCurrentAndPreviousValuesForActiveCalcs();
      // Check if the threshold was hit for each calcUuid
      Object.keys(calcValueMap).forEach((calcId) => {
        const current = calcValueMap[calcId];
        const thresholdWasHit = widget.checkIfThresholdWasHit(current, calcId);
        this.widgetAudioStateSource$.next({
          ...audioState,
          thresholdWasHit: !!thresholdWasHit,
        });
        if (thresholdWasHit) {
          if (!thresholdWasHit.repeatSound) {
            if (
              this.nonRepeatingSoundsState[calcId] === thresholdWasHit.value
            ) {
              return;
            }
            this.nonRepeatingSoundsState[calcId] = thresholdWasHit.value;
          }
          if (
            !audioState.intervalSet &&
            !audioState.audioMuted &&
            !audioState.snooze.isSnoozed
          ) {
            this.playThresholdSound(thresholdWasHit, calcId, widget);
          }
          return;
        } else {
          this.nonRepeatingSoundsState[calcId] = undefined;
          this.pauseThresholdSound();
          return;
        }
      });
    }
  }

  private isWidgetColorTypeThresholds(): boolean {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.id],
    );
    const widgetConfig = widget.config;
    const colorTypeIsThresholds =
      widgetConfig.colorType.toggled &&
      widgetConfig.colorType.value === 'thresholds';
    return colorTypeIsThresholds;
  }

  private getWidgetThresholds(): IThresholdsV2 {
    if (!this.isWidgetColorTypeThresholds()) return null;
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.id],
    );
    return widget.thresholds;
  }

  public canWidgetPlayThresholdSound(): boolean {
    const thresholds = this.getWidgetThresholds();
    if (!thresholds) return false;
    const hasSound = thresholds.custom.some((threshold) => threshold.soundId);
    return hasSound;
  }

  public canWidgetBeMuted(): boolean {
    const thresholds = this.getWidgetThresholds();
    if (!thresholds) return true;
    return thresholds.allowMute;
  }

  public toggleThresholdAudio() {
    const audioMuted = this.widgetAudioStateSource$.value.audioMuted;
    this.widgetAudioStateSource$.next({
      ...this.widgetAudioStateSource$.value,
      audioMuted: !audioMuted,
    });
    if (this.widgetAudioStateSource$.value.audioMuted) {
      this.pauseThresholdSound();
    }
    this.checkAudioState();
  }

  public toggleSnooze() {
    const snoozeSeconds = this.store.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.widgets[this.id].thresholds
          .snooze,
    );
    const snoozeState = this.widgetAudioStateSource$.value.snooze;
    // Remove the snooze if it's active, otherwise set it
    if (snoozeState.isSnoozed) {
      if (snoozeState.snoozeTimeout) {
        clearTimeout(snoozeState.snoozeTimeout);
      }

      this.widgetAudioStateSource$.next({
        ...this.widgetAudioStateSource$.value,
        snooze: {
          isSnoozed: false,
          snoozeSeconds: 0,
          snoozeUntil: null,
          snoozeTimeout: null,
        },
      });
      this.checkAudioState();
    } else {
      const secondsToSnooze = snoozeSeconds;
      const snoozeUntil = new Date();
      snoozeUntil.setSeconds(snoozeUntil.getSeconds() + secondsToSnooze);
      // After the snooze is over, remove it
      const snoozeTimeout = setTimeout(() => {
        this.widgetAudioStateSource$.next({
          ...this.widgetAudioStateSource$.value,
          snooze: {
            isSnoozed: false,
            snoozeSeconds: 0,
            snoozeUntil: null,
            snoozeTimeout: null,
          },
        });
        this.checkAudioState();
      }, secondsToSnooze * 1000);

      this.widgetAudioStateSource$.next({
        ...this.widgetAudioStateSource$.value,
        snooze: {
          isSnoozed: true,
          snoozeSeconds: secondsToSnooze,
          snoozeUntil,
          snoozeTimeout,
        },
      });
    }
    if (this.widgetAudioStateSource$.value.snooze.isSnoozed) {
      this.pauseThresholdSound();
    }
  }

  private playThresholdSound(
    threshold: IThresholdV2,
    calcId: string,
    widget: WidgetStateObject,
  ) {
    const soundId = threshold.soundId;
    if (!soundId) return;
    const shareToken = this.store.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.currentDashboard.shareToken,
    );

    this.httpRequest
      .fetchAudio(soundId, shareToken)
      .pipe(
        take(1),
        tap((response) => {
          this.pauseThresholdSound();
          const soundUrl = URL.createObjectURL(response);
          this.thresholdAudio = new Audio(soundUrl);
          if (threshold.repeatSound && threshold.repeatInterval > 0) {
            this.playAudioOnInterval(calcId);
            return;
          }
          this.thresholdAudio.loop = threshold.repeatSound;
          this.thresholdAudio.load();
          this.thresholdAudio.play().catch((error) => {
            console.error('Error playing sound:', error);
          });
        }),
        catchError((error) => {
          console.error('Error fetching sound:', error);
          this.eventQueue.dispatch('SHOW_TOAST', {
            message: 'Error fetching sound',
            status: 'danger',
            title: 'Error',
            duration: 5000,
          });
          return of(error);
        }),
      )
      .subscribe();
  }

  private getThresholdAudioTimes() {
    return JSON.parse(localStorage.getItem('thresholdAudioTimes') || '{}');
  }
  private setThresholdAudioTimes(times) {
    localStorage.setItem('thresholdAudioTimes', JSON.stringify(times));
  }

  private playAudioOnInterval(calcId: string): void {
    const audioState = this.widgetAudioStateSource$.value;
    const currentDash = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.currentDashboard,
    );
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.id],
    );
    // make sure the threshold was hit before playing the sound on interval
    // just in case it's still playing when it's not supposed to for whatever reason
    const calcValueMap = widget.getCurrentAndPreviousValuesForActiveCalcs();
    const current = calcValueMap[calcId];
    const threshold = widget.checkIfThresholdWasHit(current, calcId);
    console.log('audioState: ', audioState);
    console.log('threshold: ', threshold);
    console.log('current: ', current);
    if (
      !this.thresholdAudio ||
      audioState.audioMuted ||
      audioState.snooze.isSnoozed ||
      this.dashId !== currentDash?.id ||
      !threshold ||
      !threshold.soundId
    ) {
      return;
    }
    let loopIntervalMilliseconds = threshold.repeatInterval * 1000;
    const thresholdAudioTimes = this.getThresholdAudioTimes();
    if (
      thresholdAudioTimes[calcId] &&
      thresholdAudioTimes[calcId] > Date.now() - loopIntervalMilliseconds
    ) {
      let timeToNextInterval =
        loopIntervalMilliseconds - (Date.now() - thresholdAudioTimes[calcId]);
      timeToNextInterval = timeToNextInterval < 0 ? 0 : timeToNextInterval;
      this.thresholdAudioTimeout = setTimeout(() => {
        this.playAudioOnInterval(calcId);
      }, timeToNextInterval);
      return;
    }
    thresholdAudioTimes[calcId] = Date.now();
    this.setThresholdAudioTimes(thresholdAudioTimes);
    this.thresholdAudio.currentTime = 0;
    this.thresholdAudio.loop = false;
    this.thresholdAudio.play().catch((error) => {
      console.error('Error playing sound:', error);
    });

    // Set up the next loop
    this.thresholdAudioTimeout = setTimeout(() => {
      this.playAudioOnInterval(calcId);
    }, loopIntervalMilliseconds);
    const nextPlayTime = new Date();
    nextPlayTime.setMilliseconds(
      nextPlayTime.getMilliseconds() + loopIntervalMilliseconds,
    );
    this.widgetAudioStateSource$.next({
      ...this.widgetAudioStateSource$.value,
      intervalSet: true,
      nextPlayTime,
    });
  }

  private pauseThresholdSound() {
    if (this.thresholdAudioTimeout) {
      clearTimeout(this.thresholdAudioTimeout);
    }
    if (this.thresholdAudio) {
      this.thresholdAudio.pause();
      this.thresholdAudio = null;
    }
    this.widgetAudioStateSource$.next({
      ...this.widgetAudioStateSource$.value,
      intervalSet: false,
      nextPlayTime: null,
    });
  }

  public editWidget() {
    if (this.activatedRoute.snapshot.queryParams.widgetId !== this.id) {
      this.router.navigate(['dash-v2', this.dashId], {
        queryParams: { widgetId: this.id },
      });
    }
  }
  public openCreateTemplateDialog() {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.id],
    );
    this.dialog.open(CopyToTemplateDialogComponent, {
      context: { id: this.id, type: 'widget', name: widget.title },
    });
  }
  public deleteWidget() {
    const dialogRef = this.dialog.open(UserActionDialogComponent, {
      context: {
        ConfirmText: 'Delete',
        CancelText: 'Back',
        TitleText: '(⁠๑⁠•⁠﹏⁠•⁠) Hold up!',
        ContentText:
          'Are you sure you want to delete this widget? This action cannot be undone.',
        ActionType: 'negative',
      },
    });

    dialogRef.onClose.pipe(take(1)).subscribe((confirm) => {
      if (confirm) {
        this.store.dispatch(new Widget.DeleteWidget(this.id));
      }
    });
  }
  public openCopyWidgetDialog() {
    this.dialog.open(CopyWidgetToDashboardDialogComponent, {
      context: { widgetId: this.id },
    });
  }

  public copyNewGroupWidget() {
    this.widget$
      .pipe(
        take(1),
        tap((widget) => {
          const stateSnapshot = this.store.snapshot();
          const gridsterConfig = (stateSnapshot.app as IAppStateModel).appState
            ?.currentDashboard?.gridsterConfig;
          if (!gridsterConfig) throw new Error('Gridster config not found');
          const nextPossiblePosition =
            gridsterConfig.api.getFirstPossiblePosition({
              x: 0,
              y: 0,
              ...widget.gridsterItem,
            });
          this.store.dispatch(
            new Widget.CreateCopy(
              this.id,
              widget.dashboardV2Id,
              nextPossiblePosition,
              widget.getCopyTitle({ newDashboard: false }),
              true,
            ),
          );
        }),
      )
      .subscribe();
  }

  public async showTable(clickTarget: IWidgetClickTargetV2) {
    let widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.id],
    );
    if (!widget.hasColumnInfo) {
      this.eventQueue.dispatch('SHOW_TOAST', {
        title: 'Waiting for dataset to sync',
        message:
          'Please wait for the dataset to sync before trying to view the table',
        status: 'info',
        duration: 5000,
      });
      return;
    }
    // If the widget doesn't have any modal data, fetch it
    if (!widget.hasModalData) {
      const calcArray = widget.calcArray;

      widget.isLoading = true;
      for (let calc of calcArray) {
        this.store.dispatch(
          new App.GetFullModalData(calc.activeCalcDataId, calc.name),
        );
        await lastValueFrom(
          merge(
            this.actions.pipe(ofActionSuccessful(App.GetFullModalDataSuccess)),
            this.actions.pipe(ofActionSuccessful(App.GetFullModalDataFail)),
          ).pipe(take(1)),
        );
      }

      const fullModalData = this.store.selectSnapshot(
        (state) => (state.app as IAppStateModel).appState.fullModalData,
      );
      for (let calcId in fullModalData) {
        const calc = widget.calcArray.find(
          (calc) => calc.activeCalcDataId === calcId,
        );
        if (calc && calc.getActiveData())
          calc.getActiveData().modal_data = fullModalData[calcId];
      }
      widget.isLoading = false;
      widget = this.store.selectSnapshot(
        (state) => (state.app as IAppStateModel).appState.widgets[this.id],
      );
    }
    if (!widget.hasModalData) {
      this.eventQueue.dispatch('SHOW_TOAST', {
        title: 'No data available',
        message: 'No data available to display in the drilldown',
        status: 'info',
        duration: 5000,
      });
      return;
    }
    const modalConfig = new NbDialogConfig({});
    const modalDataList = await buildModalData(widget);
    const clickTargetForDrillDown = clickTarget?.calcTarget?.label
      ? {
          label: clickTarget.calcTarget.label,
          calcId: widget.calcArray.find(
            (c) => c.id === clickTarget.calcTarget.frontEndCalcId,
          ).activeCalcDataId,
          xValue: clickTarget.xValue,
        }
      : null;
    modalConfig.context = {
      modalDataList: modalDataList,
      modalTitle: widget.title,
      selectedTarget: clickTargetForDrillDown,
      widgetId: this.id,
      widget: widget,
    };

    this.dialog.open(DrilldownTableComponent, modalConfig);
  }

  public toggleWidgetSelectedState() {
    this.store.dispatch(new Widget.ToggleSelectedState(this.id));
  }

  public attemptResub(ids: string[]) {
    if (ids.length > 0) {
      console.log('Resubscribing to data updates for widget:', ids);
      this.store.dispatch(new Widget.SubscribeToCalculationDataUpdates(ids));
    }
  }

  /**
   * @description This method is called when the user clicks on the widget body.
   * @param event
   */
  public handleWidgetBodyClick(event: IWidgetClickTargetV2) {
    this.handleClickEvents$.next(event);
  }

  private filterVariablesForThisWidget(
    variableIds: string[],
  ): IFilterVariable[] | null {
    if (variableIds.length === 0) return null;
    const filterVariableState = this.store.selectSnapshot(
      (state) =>
        (state.app as IAppStateModel).appState.filterVariableState
          ?.currentFilterVariableState,
    );
    if (!filterVariableState) return null;
    const filterVariables = filterVariableState.filterVariables;
    const filterVariablesForThisWidget = filterVariables.filter((fv) =>
      variableIds.includes(fv.id),
    );
    return filterVariablesForThisWidget;
  }
}
