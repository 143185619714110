<nb-card>
  <nb-card-body class="main-contain">
    <h6 class="spacer">Advanced Copy Widget</h6>
    <nb-toggle class="spacer" [(checked)]="useDashSelector"
      >Copy widget to a different dashboard</nb-toggle
    >

    <!-- Widget groups needs to be more fleshed out before enabling this toggle -->
    <!-- <nb-toggle class="spacer" [(checked)]="useSameGroup">Use the same widget group</nb-toggle> -->

    <div class="dash-selector">
      <label class="label">Select a dashboard to copy the widget to</label>
      <input
        #autoInput
        nbInput
        type="text"
        (input)="onChange()"
        placeholder="Enter value"
        [nbAutocomplete]="auto"
        [disabled]="!useDashSelector"
      />
      <nb-autocomplete
        #auto
        (selectedChange)="onSelectionChange($event)"
        [handleDisplayFn]="dashDisplayFn"
      >
        <nb-option
          *ngFor="let dash of filteredDashboards$ | async"
          [value]="dash"
        >
          {{ dash.title }}
        </nb-option>
      </nb-autocomplete>
    </div>

    <div class="buttons-container">
      <button nbButton status="basic" (click)="cancel()">Cancel</button>
      <button
        nbButton
        status="primary"
        (click)="submit()"
        [nbSpinner]="isLoading"
        [disabled]="!selectedDashboard && useDashSelector"
      >
        Copy to Dashboard
      </button>
    </div>
  </nb-card-body>
</nb-card>
