import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@base-component';
import { IDashboardInfo } from '@interfaces';
import { NbDialogRef } from '@nebular/theme';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Widget } from '@root/state/app.actions';
import { IAppStateModel } from '@root/state/app.model';
import { EventQueueService } from '@services';
import { filter, map, Observable, of, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'resplendent-copy-widget-to-dashboard-dialog',
  templateUrl: './copy-widget-to-dashboard-dialog.component.html',
  styleUrls: ['./copy-widget-to-dashboard-dialog.component.scss'],
})
export class CopyWidgetToDashboardDialogComponent
  extends BaseComponent
  implements OnInit
{
  constructor(
    private store: Store,
    private actions: Actions,
    private selfRef: NbDialogRef<CopyWidgetToDashboardDialogComponent>,
    private eventQueue: EventQueueService,
  ) {
    super();
  }
  @Input() widgetId: string;

  widget$ = this.store
    .select(
      (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
    )
    .pipe(filter((v) => !!v));

  public allDashboards: IDashboardInfo[];
  public filteredDashboards$;

  public useSameGroup = false;
  public useDashSelector = true;
  public selectedDashboard: string;

  @ViewChild('autoInput') input;

  ngOnInit(): void {
    this.allDashboards = this.store
      .selectSnapshot((state) => (state.app as IAppStateModel).appState)
      .allDashboards.dashboards.sort((a, b) => a.title.localeCompare(b.title));
    this.filteredDashboards$ = of('').pipe(
      map((filterString) => this.filter(filterString)),
    );
  }
  cancel() {
    this.selfRef.close();
  }
  dashDisplayFn(dash: IDashboardInfo | string): string {
    return dash ? (typeof dash === 'object' ? dash.title : dash) : '';
  }

  private filter(value: string): IDashboardInfo[] {
    const filterValue = value.toLowerCase();
    return this.allDashboards.filter((optionValue) =>
      optionValue.title.toLowerCase().includes(filterValue),
    );
  }

  getFilteredDashboards(
    value: string | IDashboardInfo,
  ): Observable<IDashboardInfo[]> {
    value = value ? (typeof value === 'object' ? value.title : value) : '';
    return of(value).pipe(map((filterString) => this.filter(filterString)));
  }

  onChange() {
    this.filteredDashboards$ = this.getFilteredDashboards(
      this.input.nativeElement.value,
    );
    this.selectDashboard(this.input.nativeElement.value);
  }

  onSelectionChange($event) {
    this.filteredDashboards$ = this.getFilteredDashboards($event);
    this.selectDashboard($event);
  }
  public selectDashboard(val: string | IDashboardInfo) {
    if (typeof val === 'object') {
      this.selectedDashboard = val.id;
    } else {
      let isValid = false;
      for (const dash of this.allDashboards) {
        if (dash.title === val) {
          isValid = true;
          this.selectedDashboard = dash.id;
          break;
        }
      }
      if (!isValid) this.selectedDashboard = null;
    }
  }

  submit() {
    this.copyWidget(this.selectedDashboard, !this.useSameGroup);
  }

  public copyWidget(dashboardId, newGroup) {
    this.widget$
      .pipe(
        take(1),
        tap((widget) => {
          const stateSnapshot = this.store.snapshot();
          const gridsterConfig = (stateSnapshot.app as IAppStateModel).appState
            ?.currentDashboard?.gridsterConfig;
          if (!gridsterConfig) throw new Error('Gridster config not found');
          let nextPossiblePosition;
          const newDashboard =
            dashboardId && dashboardId !== widget.dashboardV2Id;
          if (
            !dashboardId ||
            dashboardId === widget.dashboardV2Id ||
            !this.useDashSelector
          ) {
            nextPossiblePosition = gridsterConfig.api.getFirstPossiblePosition({
              x: 0,
              y: 0,
              ...widget.gridsterItem,
            });
            dashboardId = widget.dashboardV2Id;
          } else {
            nextPossiblePosition = widget.gridsterItem;
          }
          this.startLoading();
          this.store.dispatch(
            new Widget.CreateCopy(
              this.widgetId,
              dashboardId,
              nextPossiblePosition,
              widget.getCopyTitle({ newDashboard: newDashboard }),
              newGroup,
            ),
          );
          this.actions
            .pipe(
              ofActionSuccessful(Widget.CreateCopySuccess),
              takeUntil(this.isDestroyed$),
              tap(() => {
                this.stopLoading();
                this.eventQueue.dispatch('SHOW_TOAST', {
                  title: 'Widget Copied!',
                  message: 'Your widget has been copied',
                  status: 'success',
                  duration: 5000,
                });
                this.selfRef.close();
              }),
            )
            .subscribe();
          this.actions.pipe(
            ofActionSuccessful(Widget.CreateCopyFail),
            takeUntil(this.isDestroyed$),
            tap(() => {
              this.stopLoading();
            }),
          );
        }),
      )
      .subscribe();
  }
}
