<nb-card class="editor-container" *ngIf="editorState$ | async as editorState">
  <nb-card-header>
    <div class="editor-header">
      Widget Editor
      <div class="editor-main-actions">
        <button
          *ngIf="step === 'config'"
          nbButton
          status="success"
          [disabled]="!widgetIsSavable"
          (click)="saveWidget()"
          size="small"
        >
          <nb-icon icon="save-outline"></nb-icon> Save & Close
        </button>
        <resplendent-close-button
          (click)="closeWidgetEditor()"
        ></resplendent-close-button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <!-- Select chart type -->
    <div *ngIf="step === 'widgetType'" [@changeStep] class="pre-step-container">
      <h1>Select Chart Type</h1>
      <div class="widget-type-container grid grid-cols-2 gap-4">
        <div
          *ngFor="let widgetDetails of widgetTypeDetails"
          (click)="selectWidgetType(widgetDetails.value)"
          class="widget-type-card"
          [ngClass]="
            widgetTypeIs(widgetDetails.value) ? 'widget-type-card-selected' : ''
          "
        >
          <img
            [src]="'/assets/chart-type-icons/' + widgetDetails.svgIcon + '.svg'"
          />
          {{ widgetDetails.title }}
        </div>
      </div>
    </div>
    <!-- Configure widget -->
    <div
      *ngIf="
        (isLoading$ | async) === false && !!form;
        else loadingWidgetFormData
      "
    >
      <div
        *ngIf="step === 'config'"
        class="widget-config-container"
        [@changeStep]
      >
        <div class="tabset-container">
          <nb-tabset class="config-tabset" fullWidth>
            <!-- Calculations tab -->
            <nb-tab
              tabId="calcs-tab"
              tabIcon="options-2-outline"
              tabTitle="Data"
            >
              <div
                class="tab-container"
                *ngIf="
                  calculationsFormArray.controls.length > 0 ||
                  !isDataBasedWidget
                "
              >
                <div class="flex justify-between">
                  <div
                    class="calc-tab-container"
                    *ngIf="(switchingCalculationTab$ | async) === false"
                    [@changeCalcTab]
                  >
                    <div class="calc-form-container" *ngIf="!showFilterEditor">
                      <ng-container
                        *ngIf="isDataBasedWidget; else noDataWidgetForm"
                      >
                        <div class="form-field-group">
                          <h6 class="mb-0">Setup</h6>
                          <div class="form-field">
                            <div class="form-field-title">
                              <p>Choose a Dataset or Modified Dataset</p>
                            </div>
                            <div class="flex gap-1">
                              <div class="w-full">
                                <input
                                  [formControl]="
                                    calculationDataStreamControl(
                                      selectedCalcIndexControl.value
                                    )
                                  "
                                  nbInput
                                  fieldSize="small"
                                  fullWidth
                                  type="text"
                                  [nbAutocomplete]="dataStreamAutoComplete"
                                  [status]="
                                    isValid(
                                      calculationDataStreamControl(
                                        selectedCalcIndexControl.value
                                      )
                                    )
                                      ? 'basic'
                                      : 'danger'
                                  "
                                  *ngIf="
                                    (switchingCalculationTab$ | async) === false
                                  "
                                />
                                <nb-autocomplete
                                  #dataStreamAutoComplete
                                  [handleDisplayFn]="
                                    dataStreamAutoCompleteDisplayFn
                                  "
                                  [activeFirst]="true"
                                >
                                  <nb-option-group
                                    *ngFor="
                                      let dataStreamType of dataStreamsByType$
                                        | async;
                                      trackBy: trackByFnForDataStreamAutoComplete
                                    "
                                    [title]="dataStreamType.typeTitle"
                                  >
                                    <nb-option
                                      *ngFor="
                                        let item of dataStreamType.dataStreams
                                      "
                                      [value]="item"
                                    >
                                      {{ item.title }}
                                    </nb-option>
                                  </nb-option-group>
                                </nb-autocomplete>
                              </div>
                              <button
                                nbButton
                                status="basic"
                                size="small"
                                (click)="toggleShowFilterEditor()"
                                [disabled]="
                                  calculationDataStreamControl(
                                    selectedCalcIndexControl.value
                                  ).invalid
                                "
                              >
                                <nb-icon icon="funnel-outline"></nb-icon>
                              </button>
                            </div>
                            <ng-container
                              *ngIf="
                                calculationDataStreamControl(
                                  selectedCalcIndexControl.value
                                ).touched
                              "
                            >
                              <div
                                class="form-error-message"
                                *ngFor="
                                  let error of calculationDataStreamControl(
                                    selectedCalcIndexControl.value
                                  ).errors | keyvalue
                                "
                              >
                                {{ error.key }}
                              </div>
                            </ng-container>
                          </div>
                          <!-- X axis select -->
                          <div
                            class="form-field"
                            *ngIf="supportsXAxis(widgetTypeControl.value)"
                          >
                            <div class="flex gap-1">
                              <div class="w-full">
                                <div class="form-field-title">
                                  <p>
                                    {{
                                      isLine
                                        ? 'Pick a date column (X Axis)'
                                        : isRadial
                                        ? 'Group By'
                                        : 'Pick a date or text column (X Axis)'
                                    }}
                                  </p>
                                </div>
                                <resplendent-column-autocomplete
                                  fieldSize="small"
                                  [columns]="
                                    calculationColumnsControl(
                                      selectedCalcIndexControl.value
                                    ).value
                                  "
                                  [disabledColumns]="getDisabledXColumns()"
                                  [selectedColumn]="
                                    calculationXAxisControl(
                                      selectedCalcIndexControl.value
                                    ).value
                                  "
                                  (selectedColumnChange)="
                                    calculationXAxisControl(
                                      selectedCalcIndexControl.value
                                    ).setValue($event)
                                  "
                                ></resplendent-column-autocomplete>
                                <ng-container
                                  *ngIf="
                                    calculationXAxisControl(
                                      selectedCalcIndexControl.value
                                    ).touched
                                  "
                                >
                                  <div
                                    class="form-error-message"
                                    *ngFor="
                                      let error of calculationXAxisControl(
                                        selectedCalcIndexControl.value
                                      ).errors | keyvalue
                                    "
                                  >
                                    {{ error.key }}
                                  </div>
                                </ng-container>
                              </div>
                              <div
                                *ngIf="
                                  getDataTypeOfXAxis(
                                    selectedCalcIndexControl.value
                                  ) === 'string'
                                "
                                class="w-36"
                              >
                                <div class="form-field-title">
                                  <p>Text Delimiter</p>
                                </div>
                                <input
                                  nbInput
                                  nbTooltip="Sometime a text column will have multiple values separated by a delimiter. This is where you can specify that delimiter, which will treat each value as a separate group."
                                  placeholder=","
                                  fieldSize="small"
                                  [formControl]="
                                    calculationDelimiterControl(
                                      selectedCalcIndexControl.value
                                    )
                                  "
                                  fullWidth
                                />
                                <ng-container
                                  *ngIf="
                                    calculationDelimiterControl(
                                      selectedCalcIndexControl.value
                                    ).touched
                                  "
                                >
                                  <div
                                    class="form-error-message"
                                    *ngFor="
                                      let error of calculationDelimiterControl(
                                        selectedCalcIndexControl.value
                                      ).errors | keyvalue
                                    "
                                  >
                                    {{ error.key }}
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-field-group"
                          *ngIf="
                            calculationDataStreamControl(
                              this.selectedCalcIndexControl.value
                            ).valid &&
                            calculationXAxisControl(
                              this.selectedCalcIndexControl.value
                            ).valid
                          "
                        >
                          <h6 class="mb-0">Options</h6>
                          <div class="form-field">
                            <div *ngIf="hasComputationColumn">
                              <div class="form-field-title">
                                <p>Choose a calculation method</p>
                              </div>
                              <nb-select
                                size="small"
                                [formControl]="
                                  calculationComputationControl(
                                    selectedCalcIndexControl.value
                                  )
                                "
                                fullWidth
                                [status]="
                                  isValid(
                                    calculationComputationControl(
                                      selectedCalcIndexControl.value
                                    )
                                  )
                                    ? 'basic'
                                    : 'danger'
                                "
                                [compareWith]="compareByValFn()"
                              >
                                <nb-option
                                  *ngFor="
                                    let computation of computations | keyvalue
                                  "
                                  [value]="computation.value"
                                  >{{ computation.value.label }}</nb-option
                                >
                              </nb-select>
                              <ng-container
                                *ngIf="
                                  calculationComputationControl(
                                    selectedCalcIndexControl.value
                                  ).touched
                                "
                              >
                                <div
                                  class="form-error-message"
                                  *ngFor="
                                    let error of calculationComputationControl(
                                      selectedCalcIndexControl.value
                                    ).errors | keyvalue
                                  "
                                >
                                  {{ error.key }}
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <div
                            *ngIf="
                              isCustomCalculation(
                                selectedCalcIndexControl.value
                              )
                            "
                          >
                            <resplendent-column-operation-gui
                              *ngIf="
                                (switchingCalculationTab$ | async) === false
                              "
                              [columns]="
                                this.calculationDataStreamControl(
                                  selectedCalcIndexControl.value
                                ).value.columnDetails
                              "
                              [virtualColumn]="{
                                columnName: '',
                                pandasFilter:
                                  calculationCustomCalculationColumnControl(
                                    selectedCalcIndexControl.value
                                  ).value
                              }"
                              [showGrouping]="false"
                              [aggregateMode]="true"
                              (virtualColumnChange)="
                                calculationCustomCalculationColumnControl(
                                  selectedCalcIndexControl.value
                                ).setValue($event)
                              "
                            ></resplendent-column-operation-gui>
                          </div>
                          <div
                            class="form-field"
                            *ngIf="
                              isPercentageCalculation(
                                selectedCalcIndexControl.value
                              )
                            "
                          >
                            <button
                              nbButton
                              size="small"
                              [status]="
                                isPercentCalculationSet(
                                  selectedCalcIndexControl.value
                                )
                                  ? 'basic'
                                  : 'warning'
                              "
                              (click)="
                                openPercentageFilterDialog(
                                  selectedCalcIndexControl.value
                                )
                              "
                            >
                              Percent Filter
                            </button>
                          </div>
                          <!-- Computation column -->
                          <div
                            class="form-field"
                            *ngIf="
                              showCalculationComputationColumn(
                                selectedCalcIndexControl.value
                              )
                            "
                          >
                            <div class="form-field-title">
                              <div class="flex justify-between w-full">
                                <p>Pick column to compute (Y Axis)</p>
                                <div class="flex justify-end gap-1">
                                  <p>Custom Y Axis</p>
                                  <nb-checkbox
                                    *ngIf="isStandard"
                                    [formControl]="
                                      calculationUseCustomCalculationColumnControl(
                                        selectedCalcIndexControl.value
                                      )
                                    "
                                  >
                                  </nb-checkbox>
                                </div>
                              </div>
                            </div>
                            <resplendent-column-autocomplete
                              *ngIf="
                                (switchingCalculationTab$ | async) === false &&
                                !calculationUseCustomCalculationColumnControl(
                                  selectedCalcIndexControl.value
                                ).value
                              "
                              fieldSize="small"
                              [columns]="
                                this.calculationColumnsControl(
                                  selectedCalcIndexControl.value
                                ).value
                              "
                              [selectedColumn]="
                                calculationComputationColumnControl(
                                  selectedCalcIndexControl.value
                                ).value
                              "
                              [disabledColumns]="getDisabledYColumns()"
                              (selectedColumnChange)="
                                calculationComputationColumnControl(
                                  selectedCalcIndexControl.value
                                ).setValue($event)
                              "
                            ></resplendent-column-autocomplete>
                          </div>

                          <!-- Dynamic String Column -->
                          <div
                            class="form-field"
                            *ngIf="widgetTypeIs('dynamicString')"
                          >
                            <div class="form-field-title">
                              <p>Value Column</p>
                            </div>
                            <resplendent-column-autocomplete
                              fieldSize="small"
                              [columns]="
                                this.calculationColumnsControl(
                                  selectedCalcIndexControl.value
                                ).value
                              "
                              [selectedColumn]="
                                dynamicStringColumnControl.value
                              "
                              (selectedColumnChange)="
                                dynamicStringColumnControl.setValue($event)
                              "
                            ></resplendent-column-autocomplete>
                          </div>
                          <!-- X Axis 2 -->
                          <div
                            class="form-field"
                            *ngIf="
                              isStandard &&
                              isChart &&
                              !isRadial &&
                              calculationXAxisControl(
                                selectedCalcIndexControl.value
                              ).value
                            "
                          >
                            <div class="flex gap-1">
                              <div class="w-full">
                                <div class="form-field-title">
                                  <p>
                                    {{ isRadial ? 'Subgroup By' : 'Group By' }}
                                  </p>
                                </div>
                                <resplendent-column-autocomplete
                                  fieldSize="small"
                                  [columns]="
                                    this.calculationColumnsControl(
                                      selectedCalcIndexControl.value
                                    ).value
                                  "
                                  [selectedColumn]="
                                    calculationXAxis2Control(
                                      selectedCalcIndexControl.value
                                    ).value
                                  "
                                  [disabledColumns]="getDisabledX2Columns()"
                                  (selectedColumnChange)="
                                    calculationXAxis2Control(
                                      selectedCalcIndexControl.value
                                    ).setValue($event)
                                  "
                                  placeholder="Optional"
                                ></resplendent-column-autocomplete>
                              </div>
                              <div class="w-36">
                                <div class="form-field-title">
                                  <p>Text Delimiter</p>
                                </div>
                                <input
                                  nbTooltip="Sometime a text column will have multiple values separated by a delimiter. This is where you can specify that delimiter, which will treat each value as a separate group."
                                  placeholder=","
                                  nbInput
                                  fieldSize="small"
                                  [formControl]="
                                    calculationDelimiter2Control(
                                      selectedCalcIndexControl.value
                                    )
                                  "
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                          <!-- Selected Columns -->
                          <div class="form-field" *ngIf="isDataBasedWidget">
                            <div class="form-field-title">
                              <p>Columns</p>
                            </div>
                            <div class="flex justify-between gap-1">
                              <div class="column-selector">
                                <ng-container
                                  *ngIf="
                                    (switchingCalculationTab$ | async) === false
                                  "
                                >
                                  <input
                                    [value]="
                                      getCalcColumnsString(
                                        selectedCalcIndexControl.value
                                      )
                                    "
                                    nbInput
                                    fieldSize="small"
                                    fullWidth
                                    [disabled]="true"
                                  />
                                  <div
                                    class="clicky"
                                    (click)="
                                      openColumnEditor(
                                        selectedCalcIndexControl.value
                                      )
                                    "
                                  ></div>
                                </ng-container>
                                <input
                                  type="text"
                                  nbInput
                                  fieldSize="small"
                                  fullWidth
                                  *ngIf="switchingCalculationTab$ | async"
                                  value="Loading..."
                                  disabled="true"
                                />
                              </div>
                              <button
                                nbButton
                                size="small"
                                status="basic"
                                [nbPopover]="copyColumnsPopover"
                                nbPopoverTrigger="click"
                              >
                                <nb-icon icon="copy-outline"></nb-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-field"
                          *ngIf="
                            widgetTypeIs('table') ||
                            widgetTypeIs('dynamicString')
                          "
                        >
                          <div class="form-field-title flex justify-between">
                            <p>Sort Drilldown Data</p>
                            <div class="flex justify-end gap-1">
                              <p>Sort Ascending</p>
                              <nb-checkbox
                                [checked]="modalDataSortAscendingControl.value"
                                (checkedChange)="
                                  modalDataSortAscendingControl.setValue($event)
                                "
                              >
                              </nb-checkbox>
                            </div>
                          </div>
                          <nb-select
                            size="small"
                            [formControl]="modalDataSortColumnControl"
                            fullWidth
                          >
                            <nb-option [value]="null">None</nb-option>
                            <nb-option
                              *ngFor="
                                let column of calculationColumnsControl(
                                  selectedCalcIndexControl.value
                                ).value;
                                trackBy: calculationColumnTrackByFn
                              "
                              [value]="column.name"
                              >{{ column.alias ?? column.name }}</nb-option
                            >
                          </nb-select>
                        </div>
                        <resplendent-column-operation-gui
                          *ngIf="
                            (switchingCalculationTab$ | async) === false &&
                            calculationUseCustomCalculationColumnControl(
                              selectedCalcIndexControl.value
                            ).value
                          "
                          [columns]="
                            this.calculationDataStreamControl(
                              selectedCalcIndexControl.value
                            ).value.columnDetails
                          "
                          [virtualColumn]="{
                            columnName: '',
                            pandasFilter:
                              calculationCustomCalculationColumnControl(
                                selectedCalcIndexControl.value
                              ).value
                          }"
                          (virtualColumnChange)="
                            calculationCustomCalculationColumnControl(
                              selectedCalcIndexControl.value
                            ).setValue($event)
                          "
                          [showGrouping]="false"
                        ></resplendent-column-operation-gui>
                      </ng-container>
                      <!-- Inputs for no data widget -->
                      <ng-template #noDataWidgetForm>
                        <!-- Image URL input -->
                        <ng-container *ngIf="!!form">
                          <div class="form-field" *ngIf="widgetTypeIs('image')">
                            <div class="form-field-title">
                              <p>Image URL</p>
                            </div>
                            <input
                              nbInput
                              fieldSize="small"
                              fullWidth
                              [formControl]="imageUrlControl"
                              [status]="
                                isValid(imageUrlControl) ? 'basic' : 'danger'
                              "
                            />
                            <ng-container *ngIf="imageUrlControl.touched">
                              <div
                                class="form-error-message"
                                *ngFor="
                                  let error of imageUrlControl.errors | keyvalue
                                "
                              >
                                {{ error.key }}
                              </div>
                            </ng-container>
                          </div>
                          <!-- IFrame URL input -->
                          <div
                            class="form-field"
                            *ngIf="widgetTypeIs('iFrame')"
                          >
                            <div class="form-field-title">
                              <p>IFrame IRL</p>
                            </div>
                            <input
                              nbInput
                              fieldSize="small"
                              fullWidth
                              [formControl]="iFrameUrlControl"
                              [status]="
                                isValid(iFrameUrlControl) ? 'basic' : 'danger'
                              "
                              placeholder="https://example.com"
                            />
                            <ng-container *ngIf="iFrameUrlControl.touched">
                              <div
                                class="form-error-message"
                                *ngFor="
                                  let error of iFrameUrlControl.errors
                                    | keyvalue
                                "
                              >
                                {{ error.key }}
                              </div>
                            </ng-container>
                          </div>
                          <div
                            class="form-field"
                            *ngIf="widgetTypeIs('staticString')"
                          >
                            <div class="form-field-title">
                              <p>Static Value</p>
                            </div>
                            <input
                              nbInput
                              fieldSize="small"
                              fullWidth
                              [formControl]="staticStringControl"
                              [status]="
                                isValid(staticStringControl)
                                  ? 'basic'
                                  : 'danger'
                              "
                            />
                            <ng-container *ngIf="staticStringControl.touched">
                              <div
                                class="form-error-message"
                                *ngFor="
                                  let error of staticStringControl.errors
                                    | keyvalue
                                "
                              >
                                {{ error.key }}
                              </div>
                            </ng-container>
                          </div>
                        </ng-container>
                      </ng-template>
                    </div>
                    <div *ngIf="showFilterEditor">
                      <div class="filter-editor-container">
                        <ng-container
                          *ngIf="
                            calculationDataStreamControl(
                              selectedCalcIndexControl.value
                            ).value;
                            else selectDataStreamFirst
                          "
                        >
                          <ng-container
                            *ngIf="
                              calculationDataStreamControl(
                                selectedCalcIndexControl.value
                              ).value.columnDetails
                            "
                          >
                            <button
                              nbButton
                              (click)="toggleShowFilterEditor()"
                              size="small"
                              style="margin-bottom: 10px"
                            >
                              <nb-icon icon="arrow-back-outline"></nb-icon> Back
                            </button>
                            <div class="mat-button-toggle-overrides-filled">
                              <mat-button-toggle-group
                                multiple="false"
                                [formControl]="
                                  calculationFilterTypeControl(
                                    selectedCalcIndexControl.value
                                  )
                                "
                              >
                                <mat-button-toggle value="none"
                                  >NO FILTER</mat-button-toggle
                                >
                                <!-- <mat-button-toggle value="simple">SIMPLE FILTER</mat-button-toggle> -->
                                <mat-button-toggle value="pandas"
                                  >ENABLE FILTERING</mat-button-toggle
                                >
                              </mat-button-toggle-group>
                            </div>
                            <div class="filter-editor">
                              <div
                                *ngIf="
                                  calculationFilterTypeControl(
                                    selectedCalcIndexControl.value
                                  ).value === 'pandas'
                                "
                              >
                                <resplendent-row-filter-gui-v2
                                  *ngIf="
                                    (switchingCalculationTab$ | async) === false
                                  "
                                  [emitOnDestroy]="false"
                                  [filterObject]="
                                    getFilterObject(
                                      selectedCalcIndexControl.value
                                    )
                                  "
                                  [columns]="
                                    calculationDataStreamControl(
                                      selectedCalcIndexControl.value
                                    ).value.columnDetails
                                  "
                                  (filterChanged)="
                                    onFilterChanged(
                                      $event,
                                      selectedCalcIndexControl.value
                                    )
                                  "
                                ></resplendent-row-filter-gui-v2>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                        <ng-template #selectDataStreamFirst>
                          <div>Select a data stream first</div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="isDataBasedWidget">
                    <ng-container
                      *ngTemplateOutlet="calculationTabs"
                    ></ng-container>
                  </ng-container>
                </div>
              </div>
            </nb-tab>

            <nb-tab
              tabId="styling"
              tabIcon="color-palette-outline"
              tabTitle="Customization"
            >
              <div class="tab-container">
                <div class="form-field" *ngIf="isRadial">
                  <div class="pie-buttons">
                    <button nbButton (click)="setToPie()" size="small">
                      Pie
                    </button>
                    <button nbButton (click)="setToDoughnut()" size="small">
                      Doughnut
                    </button>
                  </div>
                </div>
                <div class="form-field" *ngIf="isRadial">
                  <div class="slider-title">Custom Radius</div>
                  <div class="slider-container">
                    <span class="slider-value"
                      >{{ pieInnerRadiusControl.value }}% -
                      {{ pieRadiusControl.value }}%</span
                    >
                    <mat-slider min="0" max="200" step="1">
                      <input
                        matSliderStartThumb
                        [formControl]="pieInnerRadiusControl"
                      />
                      <input
                        matSliderEndThumb
                        [formControl]="pieRadiusControl"
                      />
                    </mat-slider>
                  </div>
                </div>
                <div class="form-field" *ngIf="isCartesian">
                  <nb-toggle [formControl]="xAxisControl">X Axis</nb-toggle>
                </div>
                <div class="form-field" *ngIf="isCartesian">
                  <nb-toggle [formControl]="yAxisControl">Y Axis</nb-toggle>
                </div>
                <div class="form-field" *ngIf="isChart">
                  <nb-toggle [formControl]="legendControl">Legend</nb-toggle>
                </div>
                <div class="form-field" *ngIf="isChart && legendControl.value">
                  <div class="form-field-title">
                    <p>Legend Position</p>
                  </div>
                  <nb-radio-group [formControl]="legendPositionControl">
                    <nb-radio value="top">Top</nb-radio>
                    <nb-radio value="bottom">Bottom</nb-radio>
                    <nb-radio value="left">Left</nb-radio>
                    <nb-radio value="right">Right</nb-radio>
                  </nb-radio-group>
                </div>
                <div class="form-field" *ngIf="widgetTypeIs('line')">
                  <nb-toggle
                    [formControl]="overlayCurrentNumberControl"
                    nbTooltip="Overlay the current value on top of the chart."
                    >Overlay Current Value</nb-toggle
                  >
                </div>
                <div class="form-field" *ngIf="isChart">
                  <nb-toggle [formControl]="dataLabelsControl"
                    >Data Labels</nb-toggle
                  >
                </div>
                <div class="form-field" *ngIf="containsTextValue">
                  <nb-toggle [formControl]="percentSuffixControl"
                    >Percent Suffix</nb-toggle
                  >
                </div>
                <div class="form-field" *ngIf="containsTextValue">
                  <nb-toggle [formControl]="dollarPrefixControl"
                    >Dollar Prefix</nb-toggle
                  >
                </div>
                <div class="form-field" *ngIf="containsTextValue">
                  <nb-toggle [formControl]="abbreviateNumbersControl"
                    >Abbreviate Numbers</nb-toggle
                  >
                </div>
                <!-- <div class="form-field" *ngIf="widgetTypeIs('bar')">
                  <nb-toggle [formControl]="showHistoricalDataControl"
                    >Show historical bars</nb-toggle
                  >
                </div> -->
                <div class="form-field">
                  <nb-toggle [formControl]="showWidgetTitleControl"
                    >Show widget title</nb-toggle
                  >
                </div>
                <div
                  class="form-field"
                  *ngIf="
                    !isSnapshot && (widgetTypeIs('bar') || widgetTypeIs('line'))
                  "
                >
                  <nb-toggle [formControl]="cumulativeXAxisControl"
                    >Cumulative x axis</nb-toggle
                  >
                </div>
                <div
                  class="form-field"
                  *ngIf="widgetTypeIs('line')"
                  nbTooltip="Show the area under the line"
                >
                  <nb-toggle [formControl]="showAreaControl"
                    >Show Area</nb-toggle
                  >
                </div>
                <div class="form-field" *ngIf="isChart">
                  <nb-toggle [formControl]="hideZeroLabelsControl">
                    Hide labels with zero values
                  </nb-toggle>
                </div>
                @if (widgetTypeIs('line') || widgetTypeIs('bar')){
                <div class="form-field">
                  <nb-toggle
                    [formControl]="fillEmptyPointsControl"
                    nbTooltip="On line and bar charts using a date column for the X axis, this will fill in empty points with a value of 0 if enabled."
                  >
                    Fill empty points
                  </nb-toggle>
                </div>
                } @if (widgetTypeIs('bar') || widgetTypeIs('funnel')){
                <div class="form-field">
                  <nb-toggle
                    [formControl]="horizontalChartControl"
                    nbTooltip="If enabled, the chart will be displayed horizontally instead of vertically."
                  >
                    Horizontal Chart
                  </nb-toggle>
                </div>
                } @if (widgetTypeIs('bar')){
                <div class="form-field">
                  <nb-toggle
                    [formControl]="showStackTotalControl"
                    nbTooltip="Show the total of a stacked bar at the top of each stack"
                  >
                    Show stack total
                  </nb-toggle>
                </div>
                }
                <div class="form-field" *ngIf="isCartesian">
                  <nb-toggle
                    [formControl]="comparePointsControl"
                    nbTooltip="Compare points on the chart to the previous point or a custom value"
                    >Compare Points</nb-toggle
                  >
                </div>
                <div
                  class="compare-points-container"
                  *ngIf="comparePointsControl?.value"
                >
                  <div class="form-field">
                    <div class="form-field-title">
                      <p>
                        Select how you'd like to compare points on the chart
                      </p>
                    </div>
                    <nb-select
                      [formControl]="comparisonTypeControl"
                      fullWidth
                      size="small"
                    >
                      <nb-option value="previous">Previous Point</nb-option>
                      <nb-option value="custom">Custom Value</nb-option>
                    </nb-select>
                  </div>
                  <div
                    class="form-field"
                    *ngIf="comparisonTypeControl?.value === 'custom'"
                  >
                    <div class="form-field-title">
                      <p>Custom Value</p>
                    </div>
                    <input
                      nbInput
                      fieldSize="small"
                      fullWidth
                      [formControl]="comparisonCustomValueControl"
                    />
                  </div>
                  <div class="form-field">
                    <div class="form-field-title">
                      <p>Comparison Method</p>
                    </div>
                    <nb-select
                      [formControl]="comparisonMethodControl"
                      fullWidth
                      size="small"
                    >
                      <nb-option value="percent">Percent</nb-option>
                      <nb-option value="difference">Difference</nb-option>
                    </nb-select>
                  </div>
                  <div class="form-field">
                    <nb-toggle [formControl]="comparisonOnlyShowInLabelControl">
                      Only show in hover tooltip
                    </nb-toggle>
                  </div>
                </div>
                <!-- transpose options -->
                <ng-container
                  *ngIf="widgetTypeIs('table') && !pivotTableDataControl.value"
                >
                  <div class="form-field">
                    <nb-toggle
                      [formControl]="transposeTableDataControl"
                      nbTooltip="Rotate the table data so that rows become columns and columns become rows"
                      >Transpose Table Data</nb-toggle
                    >
                  </div>
                  <div
                    class="compare-points-container"
                    *ngIf="transposeTableDataControl?.value"
                  >
                    <div class="form-field">
                      <div class="form-field-title">
                        <p>Select a column. Its values will be your columns.</p>
                      </div>

                      <nb-select
                        [formControl]="transposeColumnControl"
                        fullWidth
                        size="small"
                      >
                        <nb-option
                          *ngFor="
                            let column of calculationColumnsControl(
                              selectedCalcIndexControl.value
                            ).value
                          "
                          [value]="column.name"
                          >{{ column.alias ?? column.name }}</nb-option
                        >
                      </nb-select>
                    </div>
                    <div class="form-field">
                      <div class="form-field-title">
                        <p>Include the header as a column</p>
                      </div>
                      <nb-toggle [formControl]="transposeIncludeHeaderControl">
                        Include header
                      </nb-toggle>
                    </div>
                    <div
                      class="form-field"
                      *ngIf="transposeIncludeHeaderControl?.value"
                    >
                      <div class="form-field-title">
                        <p>Header Column Name</p>
                      </div>
                      <input
                        nbInput
                        fieldSize="small"
                        fullWidth
                        [formControl]="transposeHeaderColumnNameControl"
                      />
                    </div>
                    <div
                      class="form-field"
                      *ngIf="transposeIncludeHeaderControl?.value"
                    >
                      <div class="form-field-title">
                        <p>Pin header column</p>
                      </div>
                      <nb-toggle
                        [formControl]="transposePinHeaderColumnControl"
                      >
                        Pin header column
                      </nb-toggle>
                    </div>
                  </div>
                </ng-container>

                <!-- pivot options -->
                <ng-container
                  *ngIf="
                    widgetTypeIs('table') && !transposeTableDataControl.value
                  "
                >
                  <div class="form-field">
                    <nb-toggle
                      [formControl]="pivotTableDataControl"
                      nbTooltip="Pivot The Table Data"
                      >Pivot Table Data</nb-toggle
                    >
                  </div>
                  <div
                    class="compare-points-container"
                    *ngIf="pivotTableDataControl?.value"
                  >
                    <div class="form-field">
                      <div class="form-field-title">
                        <p>Columns.</p>
                      </div>

                      <nb-select
                        [formControl]="pivotColumnsControl"
                        fullWidth
                        multiple
                        size="small"
                      >
                        <nb-option
                          *ngFor="
                            let column of calculationColumnsControl(
                              selectedCalcIndexControl.value
                            ).value
                          "
                          [value]="column.name"
                          >{{ column.alias ?? column.name }}</nb-option
                        >
                      </nb-select>
                    </div>
                    <div class="form-field">
                      <div class="form-field-title">
                        <p>Index</p>
                      </div>

                      <nb-select
                        [formControl]="pivotIndexControl"
                        fullWidth
                        multiple
                        size="small"
                      >
                        <nb-option
                          *ngFor="
                            let column of calculationColumnsControl(
                              selectedCalcIndexControl.value
                            ).value
                          "
                          [value]="column.name"
                          >{{ column.alias ?? column.name }}</nb-option
                        >
                      </nb-select>
                    </div>
                    <div class="form-field">
                      <div class="form-field-title">
                        <p>Value Columns</p>
                      </div>

                      <nb-select
                        [formControl]="pivotValuesControl"
                        fullWidth
                        multiple
                        size="small"
                      >
                        <nb-option
                          *ngFor="
                            let column of calculationColumnsControl(
                              selectedCalcIndexControl.value
                            ).value
                          "
                          [value]="column.name"
                          >{{ column.alias ?? column.name }}</nb-option
                        >
                      </nb-select>
                    </div>
                    <div class="form-field">
                      <div class="form-field-title">
                        <p>Aggregate Function</p>
                      </div>

                      <nb-select
                        [formControl]="pivotAggFuncControl"
                        fullWidth
                        size="small"
                      >
                        <nb-option
                          *ngFor="
                            let val of [
                              'min',
                              'max',
                              'first',
                              'last',
                              'sum',
                              'mean',
                              'median',
                              'len'
                            ]
                          "
                          [value]="val"
                          >{{ val }}</nb-option
                        >
                      </nb-select>
                    </div>
                    <div class="form-field">
                      <div class="form-field-title">
                        <p>Pin Index Column(s)</p>
                      </div>
                      <nb-toggle [formControl]="pivotPinIndexColumnsControl">
                        Pin Index
                      </nb-toggle>
                    </div>
                  </div>
                </ng-container>

                <div class="form-field" *ngIf="isCartesian">
                  <nb-toggle
                    [formControl]="yAxisLogScaleControl"
                    nbTooltip="Log scale is useful if you're plotting data that has vastly different values, but you'd still like to compare. Log scale cannot render data points less than or equal to 0."
                  >
                    Y Axis Log Scale
                  </nb-toggle>
                </div>
                <div
                  class="form-field"
                  *ngIf="
                    (widgetTypeIs('matrix') || widgetTypeIs('table')) &&
                    !transposeTableDataControl?.value &&
                    !pivotTableDataControl?.value
                  "
                >
                  <div class="form-field-title"><p>Pinned Columns</p></div>
                  <nb-select
                    size="small"
                    [formControl]="pinnedColumnsControl"
                    fullWidth
                    multiple
                    *ngIf="widgetTypeIs('table')"
                  >
                    <nb-option
                      *ngFor="
                        let column of calculationColumnsControl(
                          selectedCalcIndexControl.value
                        ).value
                      "
                      [value]="column.name"
                      >{{ column.alias ?? column.name }}</nb-option
                    >
                  </nb-select>
                  <nb-select
                    size="small"
                    [formControl]="pinnedColumnsControl"
                    fullWidth
                    multiple
                    *ngIf="widgetTypeIs('matrix')"
                  >
                    <nb-option value="____name">Row Name</nb-option>
                    <nb-option
                      *ngFor="let calc of calculationsFormArray.value"
                      [value]="calc.id"
                      >{{ calc.name }}</nb-option
                    >
                  </nb-select>
                </div>
                <div
                  class="form-field"
                  *ngIf="isCartesian && yAxisLogScaleControl.value"
                >
                  <div class="form-field-title">
                    <p>Log Scale Base</p>
                    <nb-icon
                      icon="info-outline"
                      status="primary"
                      nbTooltip="If you're using a log scale for the Y Axis, the Log Base adjusts how fast it scales"
                    ></nb-icon>
                  </div>
                  <input
                    type="number"
                    [formControl]="logScaleBaseControl"
                    nbInput
                    fieldSize="small"
                    fullWidth
                    size="small"
                  />
                </div>
                <div
                  class="form-field"
                  *ngIf="isCartesian && xAxisControl.value"
                >
                  <div class="form-field-title">
                    <p>X Axis Label Rotation</p>
                  </div>
                  <input
                    type="number"
                    [formControl]="xAxisLabelRotationControl"
                    nbInput
                    fieldSize="small"
                    fullWidth
                    size="small"
                    placeholder="X Axis Label Rotation"
                  />
                </div>
                <div class="form-field" *ngIf="isCartesian">
                  <div class="form-field-title">
                    <p>Maximum Y value</p>
                  </div>
                  <input
                    nbInput
                    fieldSize="small"
                    nbTooltip="Maximum Y value (leave blank for auto)"
                    type="number"
                    fullWidth
                    [formControl]="yMaxControl"
                  />
                </div>
                <div class="form-field" *ngIf="isCartesian">
                  <div class="form-field-title">
                    <p>Minimum Y value</p>
                  </div>
                  <input
                    nbInput
                    fieldSize="small"
                    nbTooltip="Minimum Y value (leave blank for auto)"
                    type="number"
                    fullWidth
                    [formControl]="yMinControl"
                  />
                </div>
                <ng-container *ngIf="widgetTypeIs('gauge')">
                  <div class="form-field">
                    <div class="form-field-title">
                      <p>Min Gauge Value</p>
                    </div>
                    <input
                      nbInput
                      fieldSize="small"
                      nbTooltip="The minimum value of the gauge"
                      type="number"
                      fullWidth
                      [formControl]="minGaugeValueControl"
                    />
                  </div>
                  <div class="form-field">
                    <div class="form-field-title">
                      <p>Max Gauge Value</p>
                    </div>
                  </div>
                  <input
                    nbInput
                    fieldSize="small"
                    nbTooltip="The maximum value of the gauge"
                    type="number"
                    fullWidth
                    [formControl]="maxGaugeValueControl"
                  />
                </ng-container>
                <ng-container *ngIf="isCartesian">
                  <div class="form-field">
                    <div class="form-field-title">
                      <p>X Axis Label</p>
                    </div>
                    <input
                      nbInput
                      fieldSize="small"
                      nbTooltip="X Axis Label"
                      fullWidth
                      [formControl]="xLabelControl"
                    />
                  </div>
                  <div class="form-field">
                    <div class="form-field-title">
                      <p>Y Axis Label</p>
                    </div>
                    <input
                      nbInput
                      fieldSize="small"
                      nbTooltip="Y Axis Label"
                      fullWidth
                      [formControl]="yLabelControl"
                    />
                  </div>
                </ng-container>
                <div
                  class="form-field"
                  *ngIf="
                    isChart || widgetTypeIs('matrix') || widgetTypeIs('number')
                  "
                >
                  <div class="form-field-title">
                    <p>Decimal Places</p>
                  </div>
                  <input
                    nbInput
                    fieldSize="small"
                    nbTooltip="Rounding (leave blank for no rounding)"
                    type="number"
                    fullWidth
                    [formControl]="roundingControl"
                  />
                </div>
                <div
                  class="form-field"
                  *ngIf="!widgetTypeIs('matrix') && isDataBasedWidget"
                >
                  <div class="form-field-title">
                    <p>Columns with Percent Suffix</p>
                    <nb-icon
                      icon="info-outline"
                      nbTooltip="Selected columns will have their values displayed with a % suffix"
                      status="primary"
                    >
                    </nb-icon>
                  </div>
                  <nb-select
                    size="small"
                    [formControl]="columnsWithPercentSuffixControl"
                    fullWidth
                    multiple
                  >
                    <nb-option
                      *ngFor="
                        let column of calculationColumnsControl(
                          selectedCalcIndexControl.value
                        ).value
                      "
                      [value]="column.name"
                      >{{ column.alias ?? column.name }}</nb-option
                    >
                  </nb-select>
                </div>
                <div
                  class="form-field"
                  *ngIf="!widgetTypeIs('matrix') && isDataBasedWidget"
                >
                  <div class="form-field-title">
                    <p>Columns with Dollar Prefix</p>
                    <nb-icon
                      icon="info-outline"
                      nbTooltip="Selected columns will have their values displayed with a $ prefix"
                      status="primary"
                    >
                    </nb-icon>
                  </div>
                  <nb-select
                    size="small"
                    [formControl]="columnsWithDollarPrefixControl"
                    fullWidth
                    multiple
                  >
                    <nb-option
                      *ngFor="
                        let column of calculationColumnsControl(
                          selectedCalcIndexControl.value
                        ).value
                      "
                      [value]="column.name"
                      >{{ column.alias ?? column.name }}</nb-option
                    >
                  </nb-select>
                </div>
                <div class="form-field" *ngIf="isTextBased">
                  <div class="form-field-title">
                    <p>Font Size</p>
                  </div>
                  <input
                    nbInput
                    fieldSize="small"
                    nbTooltip="Font Size (leave blank for auto)"
                    fullWidth
                    [formControl]="fontSizeControl"
                  />
                </div>
                <div
                  class="form-field"
                  *ngIf="widgetTypeIs('bar') || widgetTypeIs('line')"
                >
                  <div class="form-field-title">
                    <p>Stacking</p>
                  </div>
                  <nb-select
                    size="small"
                    [formControl]="groupingControl"
                    fullWidth
                  >
                    <nb-option value="none">Don't Stack</nb-option>
                    <nb-option value="stackByLabel">Stack by label</nb-option>
                    <nb-option value="stackByCalculation"
                      >Stack by calculation</nb-option
                    >
                  </nb-select>
                </div>
                <div class="form-field" *ngIf="widgetTypeIs('matrix')">
                  <div class="form-field-title">
                    <p>Matrix Sorting Column</p>
                  </div>
                  <nb-select
                    size="small"
                    [formControl]="modalDataSortColumnControl"
                    fullWidth
                    *ngIf="
                      matrixSortOpts.includes(modalDataSortColumnControl.value)
                    "
                  >
                    <nb-option value="____name">Row Name</nb-option>
                    <nb-option
                      *ngFor="let calc of calculationsFormArray.value"
                      [value]="calc.id"
                      >{{ calc.name }}</nb-option
                    >
                  </nb-select>
                </div>
                <div
                  class="form-field"
                  *ngIf="
                    widgetTypeIs('bar') ||
                    widgetTypeIs('pie') ||
                    widgetTypeIs('line') ||
                    widgetTypeIs('matrix') ||
                    widgetTypeIs('funnel')
                  "
                >
                  <div class="form-field-title">
                    <p>Sorting Method</p>
                  </div>
                  <nb-select
                    size="small"
                    [formControl]="sortingControl"
                    fullWidth
                  >
                    <nb-option value="default">Alphabetical</nb-option>
                    <nb-option value="reverse">Reverse Alphabetical</nb-option>
                    <nb-option value="ascending">Least to Greatest</nb-option>
                    <nb-option value="descending">Greatest to Least</nb-option>
                    @if (widgetTypeIs('funnel')){
                    <nb-option value="manual"> Manual Order </nb-option>
                    } @else{
                    <nb-option
                      value="dowMonthAsc"
                      nbTooltip="Mon Tue Wed | Jan Feb Mar"
                      >By Calendar Ascending</nb-option
                    >
                    <nb-option
                      value="dowMonthDesc"
                      nbTooltip="Wed Tue Mon | Mar Feb Jan"
                      >By Calendar Descending</nb-option
                    >
                    }
                  </nb-select>
                </div>
                <div
                  class="form-field"
                  *ngIf="widgetTypeIs('bar') || widgetTypeIs('pie') || isLine"
                >
                  <div class="form-field-title">
                    <p>Group Sorting Method</p>
                  </div>
                  <nb-select
                    size="small"
                    [formControl]="groupBySortingControl"
                    fullWidth
                  >
                    <nb-option value="default">Alphabetical</nb-option>
                    <nb-option value="reverse">Reverse Alphabetical</nb-option>
                  </nb-select>
                </div>
                <div
                  class="form-field"
                  *ngIf="
                    widgetTypeIs('bar') ||
                    widgetTypeIs('pie') ||
                    widgetTypeIs('matrix')
                  "
                >
                  <div class="form-field-title">
                    <p>Limit Values</p>
                  </div>
                  <input
                    type="number"
                    nbInput
                    fieldSize="small"
                    fullWidth
                    [formControl]="valueLimitControl"
                    placeholder="Leave Blank For No Limit"
                  />
                </div>
                <div class="form-field">
                  <div class="form-field-title">
                    <p>Drilldown/Table Date Format</p>
                  </div>
                  <nb-select
                    size="small"
                    [formControl]="drilldownDateFormatControl"
                    fullWidth
                  >
                    <nb-option [value]="'local'">Local</nb-option>
                    <nb-option value="month/day/year">month/day/year</nb-option>
                    <nb-option value="year/month/day">year/month/day</nb-option>
                    <nb-option value="day/month/year">day/month/year</nb-option>
                    <nb-option value="month/day/year hour:minute:second"
                      >month/day/year hour:minute:second</nb-option
                    >
                    <nb-option value="year/month/day hour:minute:second"
                      >year/month/day hour:minute:second</nb-option
                    >
                    <nb-option value="day/month/year hour:minute:second"
                      >day/month/year hour:minute:second</nb-option
                    >
                  </nb-select>
                </div>
                <div class="form-field" *ngIf="canComparePreviousPeriod()">
                  <div class="form-field-title">
                    <p>Compare Previous Period/Year</p>
                  </div>
                  <nb-select
                    size="small"
                    [formControl]="compareWithPreviousPeriodControl"
                    fullWidth
                  >
                    <nb-option [value]="null">no comparison</nb-option>
                    <nb-option value="last_year"
                      >This period last year</nb-option
                    >
                    <nb-option value="previous_period-1"
                      >Previous Period</nb-option
                    >
                    <nb-option value="previous_period-2"
                      >2 Periods ago</nb-option
                    >
                    <nb-option value="previous_period-5"
                      >5 Periods ago</nb-option
                    >
                  </nb-select>
                </div>
                <div class="form-field" *ngIf="widgetTypeIs('matrix')">
                  <div class="form-field-title">
                    <p>Replace Missing Matrix Values</p>
                  </div>
                  <input
                    type="text"
                    nbInput
                    fieldSize="small"
                    fullWidth
                    [formControl]="matrixMissingValueControl"
                    placeholder="Enter a value to replace missing matrix values"
                  />
                </div>
                <div
                  class="form-field"
                  *ngIf="widgetTypeIs('bar')"
                  nbTooltip="The space between bars or bar groups"
                >
                  <div class="slider-title">Bar Gap</div>
                  <div class="slider-container">
                    <span class="slider-value">{{ barGapControl.value }}%</span>
                    <mat-slider min="0" max="100">
                      <input matSliderThumb [formControl]="barGapControl" />
                    </mat-slider>
                  </div>
                </div>
                <ng-container *ngIf="supportsColor">
                  <div
                    class="mat-button-toggle-overrides-filled color-picker-toggles form-field"
                  >
                    <p>Widget colors</p>
                    <mat-button-toggle-group
                      multiple="false"
                      [formControl]="colorTypeControl"
                      fullWidth
                      style="width: 100%"
                      *ngIf="thresholdsAllowed"
                    >
                      <mat-button-toggle value="colors" style="width: 100%"
                        >COLORS</mat-button-toggle
                      >
                      <mat-button-toggle value="thresholds" style="width: 100%"
                        >THRESHOLDS</mat-button-toggle
                      >
                    </mat-button-toggle-group>
                  </div>

                  <!-- Colors -->
                  <div
                    *ngIf="
                      colorTypeControl.value === 'colors' || !thresholdsAllowed
                    "
                  >
                    <div
                      *ngFor="
                        let calculationControl of calculationsFormArray.controls;
                        let i = index
                      "
                    >
                      <!-- Render only when data stream is selected and id is present -->
                      <ng-container
                        *ngIf="calculationDataStreamControl(i)?.value?.id"
                      >
                        <div class="color-picker-container">
                          <div
                            class="color-fake-input fake-input-like-display"
                            [ngStyle]="{
                              backgroundColor:
                                widgetColorMap[calculationIdControl(i).value],
                              color:
                                widgetColorMap[calculationIdControl(i).value]
                                | blackOrWhiteContrast
                            }"
                          >
                            {{ calculationNameControl(i).value }}
                          </div>
                          <button
                            *ngIf="
                              calculationUseDefaultColorControl(i).value ===
                              false
                            "
                            nbButton
                            ngx-colors-trigger
                            [palette]="primaryDefaultColors"
                            format="hex"
                            [formControl]="calculationColorControl(i)"
                          >
                            <nb-icon icon="color-palette-outline"></nb-icon>
                          </button>
                          <nb-toggle
                            [formControl]="calculationUseDefaultColorControl(i)"
                            >Use Default</nb-toggle
                          >
                        </div>
                      </ng-container>
                    </div>
                    <br />
                    <div class="hint-text max-w-48">
                      Default colors may be overridden by custom label colors in
                      the dashboard settings. If you want to use a specific
                      color for a data stream, you can override the default
                      color by selecting a custom color.
                    </div>
                  </div>
                  <!-- Thresholds -->
                  <div
                    class="mt-3"
                    *ngIf="colorTypeControl.value === 'thresholds'"
                  >
                    <div
                      class="thresholds-container"
                      *ngIf="!useCalculationThresholdsControl?.value"
                    >
                      <div
                        class="threshold-container"
                        *ngFor="
                          let formGroup of customThresholdsFormArray.controls;
                          let i = index
                        "
                      >
                        <div
                          class="color-box"
                          [ngStyle]="{
                            backgroundColor:
                              getCustomThresholdColorControl(i).value,
                            color:
                              getCustomThresholdColorControl(i).value
                              | blackOrWhiteContrast
                          }"
                        >
                          <ng-container
                            *ngIf="!getCustomThresholdValueControl(i - 1)"
                          >
                            > {{ getCustomThresholdValueControl(i).value }}
                          </ng-container>
                          <ng-container
                            *ngIf="getCustomThresholdValueControl(i - 1)"
                          >
                            {{ getCustomThresholdValueControl(i - 1).value }}
                            -
                            {{ getCustomThresholdValueControl(i).value }}
                          </ng-container>
                        </div>
                        <button nbButton>
                          <nb-icon
                            icon="color-palette-outline"
                            ngx-colors-trigger
                            [palette]="primaryDefaultColors"
                            format="hex"
                            [formControl]="getCustomThresholdColorControl(i)"
                          ></nb-icon>
                        </button>
                        <button
                          [nbPopover]="thresholdSoundSettings"
                          [nbPopoverContext]="{
                            thresholdIndex: i,
                            popoverRef: popoverRef
                          }"
                          #popoverRef="nbPopover"
                          nbPopoverTrigger="click"
                          nbButton
                          [status]="
                            getCustomThresholdSoundIdControl(i).value === null
                              ? 'basic'
                              : 'primary'
                          "
                        >
                          <nb-icon icon="volume-up-outline"></nb-icon>
                        </button>
                        <button (click)="removeCustomThreshold(i)" nbButton>
                          <nb-icon icon="close-outline"></nb-icon>
                        </button>
                        <input
                          [formControl]="getCustomThresholdValueControl(i)"
                          (blur)="onCustomThresholdValueBlur()"
                          type="number"
                          class="threshold-value-input"
                          nbInput
                          fieldSize="small"
                          [nbTooltip]="
                            thresholdTooltips[widgetTypeControl.value].custom
                          "
                        />
                      </div>
                      <div class="threshold-container">
                        <div
                          class="color-box"
                          [ngStyle]="{
                            backgroundColor: bottomThresholdControl.value,
                            color:
                              bottomThresholdControl.value
                              | blackOrWhiteContrast
                          }"
                        >
                          <
                          {{
                            getCustomThresholdValueControl(
                              customThresholdsFormArray.controls.length - 1
                            ).value
                          }}
                        </div>
                        <button nbButton>
                          <nb-icon
                            icon="color-palette-outline"
                            ngx-colors-trigger
                            [palette]="primaryDefaultColors"
                            format="hex"
                            [formControl]="bottomThresholdControl"
                          ></nb-icon>
                        </button>

                        <button (click)="addCustomThreshold()" nbButton>
                          <nb-icon icon="plus-outline"></nb-icon>
                        </button>
                      </div>
                      <div class="form-field">
                        <nb-toggle [formControl]="showThresholdMarkersControl">
                          {{
                            widgetTypeIs('matrix')
                              ? 'Use cell color for threshold'
                              : 'Show Threshold Markers'
                          }}
                        </nb-toggle>
                      </div>
                    </div>
                    <div
                      *ngIf="useCalculationThresholdsControl?.value"
                      class="form-field"
                    >
                      <br />
                      <p class="hint-text">
                        Select calculations to add a threshold too
                      </p>
                      <nb-select
                        size="small"
                        multiple
                        fullWidth
                        [(selected)]="selectedThresholdsByCalculations"
                      >
                        <nb-option
                          *ngFor="
                            let calcOpt of selectedThresholdsByCalculationsOptions
                          "
                          [value]="calcOpt.value"
                          (click)="newCalculationThresholdGroup(calcOpt.value)"
                          >{{ calcOpt.label }}</nb-option
                        >
                      </nb-select>
                      <br />
                    </div>
                    <div
                      class="thresholds-container"
                      *ngIf="useCalculationThresholdsControl?.value"
                    >
                      <div
                        *ngFor="
                          let calcId of calculationOrderControl.value;
                          let ci = index
                        "
                      >
                        <ng-container
                          *ngIf="!!thresholdsByCalculationControl?.controls?.[calcId]"
                        >
                          <h6>{{ calculationNameControl(ci).value }}</h6>
                          <div
                            class="threshold-container"
                            *ngFor="
                              let formGroup of calcThresholdsCustomControl(
                                calcId
                              ).controls;
                              let i = index
                            "
                          >
                            <div
                              class="color-box"
                              [ngStyle]="{
                                backgroundColor:
                                  getCustomCalcThresholdColorControl(calcId, i)
                                    ?.value,
                                color:
                                  getCustomCalcThresholdColorControl(calcId, i)
                                    ?.value | blackOrWhiteContrast
                              }"
                            >
                              <ng-container
                                *ngIf="
                                  !getCustomCalcThresholdValueControl(
                                    calcId,
                                    i - 1
                                  )
                                "
                              >
                                >
                                {{
                                  getCustomCalcThresholdValueControl(calcId, i)
                                    ?.value
                                }}
                              </ng-container>
                              <ng-container
                                *ngIf="
                                  getCustomCalcThresholdValueControl(
                                    calcId,
                                    i - 1
                                  )
                                "
                              >
                                {{
                                  getCustomCalcThresholdValueControl(
                                    calcId,
                                    i - 1
                                  )?.value
                                }}
                                -
                                {{
                                  getCustomCalcThresholdValueControl(calcId, i)
                                    ?.value
                                }}
                              </ng-container>
                            </div>
                            <button nbButton>
                              <nb-icon
                                icon="color-palette-outline"
                                ngx-colors-trigger
                                [palette]="primaryDefaultColors"
                                format="hex"
                                [formControl]="
                                  getCustomCalcThresholdColorControl(calcId, i)
                                "
                              ></nb-icon>
                            </button>
                            <button
                              [nbPopover]="thresholdSoundSettings"
                              [nbPopoverContext]="{
                                calcId: calcId,
                                thresholdIndex: i,
                                popoverRef: popoverRef
                              }"
                              #popoverRef="nbPopover"
                              nbPopoverTrigger="click"
                              nbButton
                              [status]="
                                !getCustomThresholdSoundIdControl(i, calcId)
                                  ?.value
                                  ? 'basic'
                                  : 'primary'
                              "
                            >
                              <nb-icon icon="volume-up-outline"></nb-icon>
                            </button>
                            <button
                              (click)="removeCustomThreshold(i, calcId)"
                              nbButton
                            >
                              <nb-icon icon="close-outline"></nb-icon>
                            </button>
                            <input
                              [formControl]="
                                getCustomCalcThresholdValueControl(calcId, i)
                              "
                              (blur)="onCustomCalcThresholdValueBlur(calcId)"
                              type="number"
                              class="threshold-value-input"
                              nbInput
                              fieldSize="small"
                              [nbTooltip]="
                                thresholdTooltips[widgetTypeControl?.value]
                                  .custom
                              "
                            />
                          </div>
                          <div class="threshold-container">
                            <div
                              class="color-box"
                              [ngStyle]="{
                                backgroundColor:
                                  calcBottomThresholdControl(calcId)?.value,
                                color:
                                  calcBottomThresholdControl(calcId)?.value
                                  | blackOrWhiteContrast
                              }"
                            >
                              <
                              {{
                                getCustomCalcThresholdValueControl(
                                  calcId,
                                  customThresholdsFormArray.controls.length - 1
                                )?.value
                              }}
                            </div>
                            <button nbButton>
                              <nb-icon
                                icon="color-palette-outline"
                                ngx-colors-trigger
                                [palette]="primaryDefaultColors"
                                format="hex"
                                [formControl]="
                                  calcBottomThresholdControl(calcId)
                                "
                              ></nb-icon>
                            </button>

                            <button
                              (click)="addCustomThreshold(calcId)"
                              nbButton
                            >
                              <nb-icon icon="plus-outline"></nb-icon>
                            </button>
                          </div>
                        </ng-container>
                      </div>
                      <br />

                      <div class="form-field">
                        <nb-toggle [formControl]="showThresholdMarkersControl">
                          {{
                            widgetTypeIs('matrix')
                              ? 'Use cell color for threshold'
                              : 'Show Threshold Markers'
                          }}
                        </nb-toggle>
                      </div>
                    </div>

                    <ng-container *ngIf="widgetTypeIs('matrix')">
                      <br />
                      <p class="hint-text">Use Calculation Threholds</p>
                      <nb-toggle [formControl]="useCalculationThresholdsControl"
                        >Use Calculation Threholds</nb-toggle
                      >
                    </ng-container>
                    <br />
                    <p class="hint-text">
                      Enable the muting of threshold alerts
                    </p>
                    <nb-toggle [formControl]="thresholdAllowMuteControl"
                      >Enable Mute</nb-toggle
                    >
                    <p class="hint-text">
                      (Note: Even if muting is disabled, you can still snooze
                      the alert sound)
                    </p>
                    <br />
                    <div class="form-field">
                      <p class="hint-text">Threshold Sound Snooze Time</p>
                      <nb-select
                        size="small"
                        [formControl]="thresholdSnoozeTimeControl"
                        fullWidth
                      >
                        <nb-option
                          *ngFor="let snoozeTime of thresholdSnoozeTimes"
                          [value]="snoozeTime.value"
                          >{{ snoozeTime.label }}</nb-option
                        >
                      </nb-select>
                    </div>
                  </div>
                </ng-container>
              </div>
            </nb-tab>
            <nb-tab
              tabId="global-options"
              tabIcon="settings-2-outline"
              tabTitle="Global Options"
            >
              <div class="tab-container">
                <div class="form-field-group">
                  <!-- Time Increment -->
                  <div class="form-field" *ngIf="isStandard && isTimeBased">
                    <div class="form-field-title">
                      <p>Time Increment</p>
                    </div>
                    <nb-select
                      size="small"
                      [formControl]="timeIncrementControl"
                      fullWidth
                    >
                      <nb-option [value]="null">None</nb-option>
                      <nb-option
                        *ngFor="let val of timeIncrementValues"
                        [value]="val"
                        >{{ val | titlecase }}</nb-option
                      >
                    </nb-select>
                  </div>
                  <div class="form-field" *ngIf="widgetTypeIs('matrix')">
                    <div class="form-field-title">
                      <p>Matrix Index</p>
                    </div>
                    <nb-select
                      size="small"
                      [formControl]="xAxisBaselineControl"
                      fullWidth
                    >
                      <nb-option [value]="null">None</nb-option>
                      <nb-option
                        *ngFor="
                          let val of (filterVariableState$ | async)
                            .filterVariables
                        "
                        [value]="val.id"
                        >{{ val.name }}</nb-option
                      >
                    </nb-select>
                  </div>
                  <div class="form-field" *ngIf="widgetTypeIs('matrix')">
                    <div class="form-field-title">
                      <p>Matrix Index Label</p>
                    </div>
                    <input
                      type="text"
                      nbInput
                      [formControl]="matrixIndexLabelControl"
                      fieldSize="small"
                      fullWidth
                    />
                  </div>
                  <!-- Timezone -->
                  <div class="form-field" *ngIf="isDataBasedWidget">
                    <div class="form-field-title">
                      <p>Widget Timezone</p>
                    </div>
                    <nb-select
                      size="small"
                      [formControl]="timezoneControl"
                      fullWidth
                    >
                      <nb-option
                        *ngFor="let val of widgetTimezones"
                        [value]="val"
                        >{{ val }}</nb-option
                      >
                    </nb-select>
                  </div>
                  <!-- Widget Description -->
                  <div class="form-field">
                    <div class="form-field-title">
                      <p>Widget Description</p>
                    </div>
                    <resplendent-markdown-editor
                      (markdownChange)="descriptionControl.setValue($event)"
                      [initialMarkdown]="descriptionControl.value"
                    ></resplendent-markdown-editor>
                  </div>
                </div>
              </div>
            </nb-tab>
          </nb-tabset>
        </div>
        <div class="widget-preview-container" *ngIf="formLoaded">
          <div class="widget-container">
            <div class="widget-action-buttons">
              <input
                nbInput
                fieldSize="small"
                placeholder="Widget Name"
                [formControl]="titleControl"
                fullWidth
                [status]="isValid(titleControl) ? 'basic' : 'danger'"
              />
            </div>
            <div class="widget">
              <resplendent-widget
                [id]="widgetId"
                [isInGridster]="false"
                [isInEditor]="true"
                [isNewWidget]="isNewWidget"
              ></resplendent-widget>
            </div>
            <div class="chart-types-container">
              <div
                *ngFor="let widgetDetails of widgetTypeDetails"
                (click)="selectWidgetType(widgetDetails.value)"
                class="widget-type-icon-card"
                [ngClass]="
                  widgetTypeIs(widgetDetails.value)
                    ? 'widget-type-card-selected'
                    : ''
                "
              >
                <img
                  [src]="
                    '/assets/chart-type-icons/' + widgetDetails.svgIcon + '.svg'
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #calculationTabs>
  <div
    class="calc-tabs-container"
    id="calc-names"
    cdkDropList
    (cdkDropListDropped)="dropCalc($event, calculationsFormArray.controls)"
  >
    <div class="flex justify-between items-center">
      <h6 class="flex items-center mb-0">Datasets</h6>
      <nb-icon
        icon="question-mark-circle-outline"
        nbTooltip="A widget can have multiple datasets. It can be useful for comparing datasets or adding the same dataset multiple times with different filters."
      ></nb-icon>
    </div>
    <div
      cdkDrag
      *ngFor="let calc of calculationsFormArray.controls; let i = index"
      class="calc-tab"
      [ngClass]="{
        'selected-calc-tab': i === selectedCalcIndexControl.value,
        'unselected-calc-tab': i !== selectedCalcIndexControl.value
      }"
      (click)="selectActiveCalculation(i)"
    >
      <div *cdkDragPlaceholder class="calc-tab-drag-placeholder"></div>
      <div
        class="calc-name"
        [nbTooltip]="
          calculationNameControl(i).value
            ? calculationNameControl(i).value
            : 'Dataset ' + (i + 1)
        "
        nbTooltipPlacement="right"
      >
        {{ i + 1 }}
        {{ '. ' }}
        {{
          calculationNameControl(i).value
            ? calculationNameControl(i).value
            : 'Dataset ' + (i + 1)
        }}
      </div>
      <div class="tab-actions">
        <button
          nbButton
          ghost
          size="small"
          status="control"
          [nbPopover]="calculationPopoverRename"
          nbPopoverTrigger="click"
          [nbPopoverContext]="{ calcIndex: i }"
          style="padding: 0.25rem 0"
          class="calc-tab-options"
        >
          <nb-icon icon="edit-2-outline"></nb-icon>
        </button>
        <button
          nbButton
          ghost
          size="small"
          status="control"
          [nbPopover]="calculationPopoverOptions"
          nbPopoverTrigger="click"
          [nbPopoverContext]="{ calcIndex: i }"
          style="padding: 0.25rem 0"
          class="calc-tab-options"
        >
          <nb-icon icon="more-vertical-outline"></nb-icon>
        </button>
      </div>
    </div>
    <div class="add-dataset-btn-container">
      <button
        *ngIf="supportsMultipleCalculations(widgetTypeControl.value)"
        nbButton
        status="basic"
        ghost
        size="small"
        (click)="addCalculation()"
        fullWidth
      >
        <nb-icon icon="plus-outline"></nb-icon>
        <span>Add Dataset</span>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #calculationPopoverOptions let-popoverVariables>
  <div class="calculation-popover">
    <button
      nbButton
      ghost
      size="small"
      (click)="removeCalculation(popoverVariables.calcIndex)"
      status="danger"
    >
      <nb-icon icon="trash-2-outline"></nb-icon>
      <span>Delete</span>
    </button>
    <button
      nbButton
      ghost
      size="small"
      (click)="copyCalculation(popoverVariables.calcIndex)"
    >
      <nb-icon icon="copy-outline"></nb-icon>
      <span>Duplicate</span>
    </button>
  </div>
</ng-template>
<ng-template #calculationPopoverRename let-popoverVariables>
  <div class="calculation-popover">
    <input
      resplendentFocusSelect
      nbInput
      fieldSize="small"
      fullWidth
      [formControl]="calculationNameControl(popoverVariables.calcIndex)"
      (change)="setSelectedThresholdsByCalculationsOptions()"
    />
  </div>
</ng-template>

<ng-template #copyColumnsPopover>
  <nb-card class="mb-0 max-w-64 max-h-72">
    <nb-card-body>
      <ng-container *ngIf="otherWidgetsAndCalcs.length > 0">
        <div
          *ngFor="let oWidget of otherWidgetsAndCalcs"
          class="copy-columns-group"
        >
          <div class="group-title">{{ oWidget.title }}</div>
          <div class="group-calcs">
            <div class="group-item" *ngFor="let calc of oWidget.calculations">
              <div class="flex justify-between items-center">
                <div class="calc-title">
                  {{ calc.name }}
                </div>
                <button
                  nbButton
                  ghost
                  size="small"
                  (click)="copyColumnsFromCalc([oWidget?.id, calc?.id])"
                >
                  <nb-icon icon="copy-outline"></nb-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="otherWidgetsAndCalcs.length === 0">
        <h6>No other widgets found</h6>
        <p>
          There aren't any other widgets on this dashboard that use the same
          dataset
        </p>
      </ng-container>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #loadingWidgetFormData>
  <resplendent-graph-animated-bar-loader></resplendent-graph-animated-bar-loader>
</ng-template>
<ng-template #thresholdSoundSettings let-popoverVars>
  <div class="threshold-sound-settings-popover">
    <p class="hint-text">Play a sound on this threshold</p>
    <ng-container *ngIf="(thresholdSoundboard$ | async).sounds.length > 0">
      <div class="sound-select-container">
        <div class="sound-selector">
          <nb-select
            size="small"
            [formControl]="
              getCustomThresholdSoundIdControl(
                popoverVars.thresholdIndex,
                popoverVars.calcId
              )
            "
            fullWidth
            placeholder="Select a sound"
          >
            <nb-option (click)="onOptionClick($event)" [value]="null"
              >No Sound</nb-option
            >
            <nb-option
              *ngFor="let sound of (thresholdSoundboard$ | async).sounds"
              (click)="onOptionClick($event)"
              [value]="sound.id"
              >{{ sound.title }}</nb-option
            >
          </nb-select>
          <button
            nbButton
            ghost
            nbTooltip="Play Sound"
            (click)="
              playThresholdSound(
                getCustomThresholdSoundIdControl(
                  popoverVars.thresholdIndex,
                  popoverVars.calcId
                ).value
              )
            "
            [disabled]="
              !getCustomThresholdSoundIdControl(
                popoverVars.thresholdIndex,
                popoverVars.calcId
              ).value
            "
          >
            <nb-icon icon="play-circle-outline"></nb-icon>
          </button>
        </div>
        <nb-radio-group
          [formControl]="
            getCustomThresholdComparatorControl(
              popoverVars.thresholdIndex,
              popoverVars.calcId
            )
          "
        >
          <nb-radio
            value="gte"
            [nbPopover]="crossingFromBelowExample"
            nbPopoverPlacement="right"
            >Greater than or equal to threshold</nb-radio
          >
          <nb-radio
            value="lte"
            [nbPopover]="crossingFromAboveExample"
            nbPopoverPlacement="right"
            >Less than or equal to threshold</nb-radio
          >
          <nb-radio
            value="eq"
            [nbPopover]="crossingFromEitherExample"
            nbPopoverPlacement="right"
            >Equal to threshold</nb-radio
          >
        </nb-radio-group>
        <nb-toggle
          [formControl]="
            getCustomThresholdRepeatSoundControl(
              popoverVars.thresholdIndex,
              popoverVars.calcId
            )
          "
          nbTooltip="The sound will continue to play until the threshold isn't met."
          nbTooltipPlacement="right"
          >Repeat Sound</nb-toggle
        >
        <nb-select
          size="small"
          fullWidth
          *ngIf="
            getCustomThresholdRepeatSoundControl(
              popoverVars.thresholdIndex,
              popoverVars.calcId
            ).value
          "
          [formControl]="
            getCustomThresholdRepeatIntervalControl(
              popoverVars.thresholdIndex,
              popoverVars.calcId
            )
          "
        >
          <nb-option (click)="onOptionClick($event)" [value]="0"
            >Continually Repeat</nb-option
          >
          <nb-option (click)="onOptionClick($event)" [value]="30"
            >30 Seconds</nb-option
          >
          <nb-option (click)="onOptionClick($event)" [value]="60"
            >1 Minute</nb-option
          >
          <nb-option (click)="onOptionClick($event)" [value]="120"
            >2 Minutes</nb-option
          >
          <nb-option (click)="onOptionClick($event)" [value]="300"
            >5 Minutes</nb-option
          >
          <nb-option (click)="onOptionClick($event)" [value]="600"
            >10 Minutes</nb-option
          >
          <nb-option (click)="onOptionClick($event)" [value]="900"
            >15 Minutes</nb-option
          >
          <nb-option (click)="onOptionClick($event)" [value]="1800"
            >30 Minutes</nb-option
          >
          <nb-option (click)="onOptionClick($event)" [value]="3600"
            >1 Hour</nb-option
          >
        </nb-select>
      </div>
    </ng-container>
    <ng-container *ngIf="(thresholdSoundboard$ | async).sounds.length === 0">
      <h6>No sounds found</h6>
      <p>
        Add sounds in the
        <a href="/manage-soundboard" target="_blank">soundboard</a>
      </p>
    </ng-container>
    <br />
    <button
      nbButton
      size="tiny"
      status="primary"
      (click)="closePopover(popoverVars.popoverRef)"
    >
      Done
    </button>
  </div>
</ng-template>
<ng-template #crossingFromBelowExample>
  <div class="threshold-sound-example-popover">
    <p>
      Play a sound when the latest value is greater than or equal to a threshold
    </p>
    <svg
      width="600"
      height="300"
      viewBox="0 0 600 300"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- Threshold -->
      <line
        x1="0"
        y1="150"
        x2="600"
        y2="150"
        stroke="black"
        stroke-dasharray="5"
      />

      <!-- Line chart (first part - blue) -->
      <path
        id="chartLine1"
        d="M0,100 L100,140 L200,110, L300,150"
        fill="none"
        stroke="#FF3D71"
        stroke-width="2"
        stroke-dasharray="1000"
        stroke-dashoffset="1000"
      >
        <!-- Animate the first part of the line chart -->
        <animate
          attributeName="stroke-dashoffset"
          from="1000"
          to="0"
          dur="2s"
          fill="freeze"
        />
      </path>

      <!-- Line chart (second part - red) -->
      <path
        id="chartLine2"
        d="M300,150 L400,200 L450, 150"
        fill="none"
        stroke="#0095FF"
        stroke-width="2"
        stroke-dasharray="1000"
        stroke-dashoffset="1000"
      >
        <!-- Animate the second part of the line chart -->
        <animate
          attributeName="stroke-dashoffset"
          from="1000"
          to="0"
          dur="2s"
          begin="0.6s"
          fill="freeze"
        />
      </path>

      <!-- Line chart (third part - blue) -->
      <path
        id="chartLine3"
        d="M450, 150 L500,100 L600, 125"
        fill="none"
        stroke="#FF3D71"
        stroke-width="2"
        stroke-dasharray="500"
        stroke-dashoffset="500"
      >
        <!-- Animate the third part of the line chart -->
        <animate
          attributeName="stroke-dashoffset"
          from="500"
          to="0"
          dur="1s"
          begin="1.2s"
          fill="freeze"
        />
      </path>

      <!-- Point where the chart and threshold meet (first) -->
      <circle
        id="meetingPoint1"
        cx="300"
        cy="150"
        r="5"
        fill="#FF3D71"
        opacity="0"
      >
        <!-- Animate the circle's opacity -->
        <animate
          attributeName="opacity"
          from="0"
          to="1"
          begin="0.6s"
          dur="0.1s"
          fill="freeze"
        />
      </circle>

      <!-- Point where the chart and threshold meet (second) -->
      <circle
        id="meetingPoint2"
        cx="450"
        cy="150"
        r="5"
        fill="#FF3D71"
        opacity="0"
      >
        <!-- Animate the circle's opacity -->
        <animate
          attributeName="opacity"
          from="0"
          to="1"
          begin="1s"
          dur="0.1s"
          fill="freeze"
        />
      </circle>
    </svg>
  </div>
</ng-template>
<ng-template #crossingFromAboveExample>
  <div class="threshold-sound-example-popover">
    <p>
      Play a sound when the latest value is less than or equal to a threshold
    </p>
    <svg
      width="600"
      height="300"
      viewBox="0 0 600 300"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- Threshold -->
      <line
        x1="0"
        y1="150"
        x2="600"
        y2="150"
        stroke="black"
        stroke-dasharray="5"
      />

      <!-- Line chart (first part - blue) -->
      <path
        id="chartLine1"
        d="M0,100 L100,140 L200,110, L300,150"
        fill="none"
        stroke="#0095FF"
        stroke-width="2"
        stroke-dasharray="1000"
        stroke-dashoffset="1000"
      >
        <!-- Animate the first part of the line chart -->
        <animate
          attributeName="stroke-dashoffset"
          from="1000"
          to="0"
          dur="2s"
          fill="freeze"
        />
      </path>

      <!-- Line chart (second part - red) -->
      <path
        id="chartLine2"
        d="M300,150 L400,200 L450, 150"
        fill="none"
        stroke="#FF3D71"
        stroke-width="2"
        stroke-dasharray="1000"
        stroke-dashoffset="1000"
      >
        <!-- Animate the second part of the line chart -->
        <animate
          attributeName="stroke-dashoffset"
          from="1000"
          to="0"
          dur="2s"
          begin="0.6s"
          fill="freeze"
        />
      </path>

      <!-- Line chart (third part - blue) -->
      <path
        id="chartLine3"
        d="M450, 150 L500,100 L600, 125"
        fill="none"
        stroke="#0095FF"
        stroke-width="2"
        stroke-dasharray="500"
        stroke-dashoffset="500"
      >
        <!-- Animate the third part of the line chart -->
        <animate
          attributeName="stroke-dashoffset"
          from="500"
          to="0"
          dur="1s"
          begin="1.2s"
          fill="freeze"
        />
      </path>

      <!-- Point where the chart and threshold meet (first) -->
      <circle
        id="meetingPoint1"
        cx="300"
        cy="150"
        r="5"
        fill="#FF3D71"
        opacity="0"
      >
        <!-- Animate the circle's opacity -->
        <animate
          attributeName="opacity"
          from="0"
          to="1"
          begin="0.6s"
          dur="0.1s"
          fill="freeze"
        />
      </circle>

      <!-- Point where the chart and threshold meet (second) -->
      <circle
        id="meetingPoint2"
        cx="450"
        cy="150"
        r="5"
        fill="#FF3D71"
        opacity="0"
      >
        <!-- Animate the circle's opacity -->
        <animate
          attributeName="opacity"
          from="0"
          to="1"
          begin="1s"
          dur="0.1s"
          fill="freeze"
        />
      </circle>
    </svg>
  </div>
</ng-template>
<ng-template #crossingFromEitherExample>
  <div class="threshold-sound-example-popover">
    <p>Play a sound when the latest value is equal to a threshold.</p>
    <svg
      width="600"
      height="300"
      viewBox="0 0 600 300"
      xmlns="http://www.w3.org/2000/svg"
    >
      <!-- Threshold -->
      <line
        x1="0"
        y1="150"
        x2="600"
        y2="150"
        stroke="black"
        stroke-dasharray="5"
      />

      <!-- Line chart -->
      <path
        id="chartLine"
        d="M0,100 L100,140 L200,110 L300,150 L400,200 L500,100"
        fill="none"
        stroke="#0095FF"
        stroke-width="2"
        stroke-dasharray="2000"
        stroke-dashoffset="2000"
      >
        <!-- Animate the line chart -->
        <animate
          attributeName="stroke-dashoffset"
          from="2000"
          to="0"
          dur="4s"
          fill="freeze"
        />
      </path>

      <!-- Point where the chart and threshold meet (first) -->
      <circle
        id="meetingPoint1"
        cx="300"
        cy="150"
        r="5"
        fill="#FF3D71"
        opacity="0"
      >
        <!-- Animate the circle's opacity -->
        <animate
          attributeName="opacity"
          from="0"
          to="1"
          begin="0.6s"
          dur="0.1s"
          fill="freeze"
        />
      </circle>

      <!-- Point where the chart and threshold meet (second) -->
      <circle
        id="meetingPoint2"
        cx="450"
        cy="150"
        r="5"
        fill="#FF3D71"
        opacity="0"
      >
        <!-- Animate the circle's opacity -->
        <animate
          attributeName="opacity"
          from="0"
          to="1"
          begin="1s"
          dur="0.1s"
          fill="freeze"
        />
      </circle>
    </svg>
  </div>
</ng-template>
